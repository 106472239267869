

.slider {
  &__wrap {
    position: relative; }
  &__dots {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: center;
    button {
      @include hidetext;
      height: 20px;
      width: 34px;
      display: inline-block;
      position: relative;
      background-color: transparent;
      &::before {
        content: '';
        position: absolute;
        @include vcenter;
        background-color: #aaaaaa;
        height: 1px;
        width: 100%; } }
    li {
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px;
      &.slick-active {
        button {
          &::before {
            background-color: #fff;
            height: 4px; } } } } }
  &__prev,
  &__next {
    background-color: transparent;
    @include icon-arrow(15, 15, 2, #fff, 45);
    @include lg {
      display: none !important; } }
  &__prev-bg,
  &__next-bg {
    width: 58px;
    height: 58px;
    background-color: rgba(#000, .5);
    border: none;
    transform: rotate(0deg) translate(0, -50%);
    position: absolute;
    top: 50%;
    @include lg {
      display: inline-block !important; }
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 20px; } }
  &__prev-bg {
    left: -135px;
    @include xl {
      left: 0px; }
    &::before {
      @include icon-arrow(15, 15, 2, #fff, 45);
      left: 23px; } }
  &__next-bg {
    right: -135px;
    @include xl {
      right: 0px; }
    &::before {
      @include icon-arrow(15, 15, 2, #fff, 225);
      left: 16px; } }

  &--full {
    .slide {
      &__inner {
        display: flex;
        align-items: center;
        position: relative;
        @include cover;
        .container {
          z-index: 1; } } }
    &__wrap {
      .slider__prev,
      .slider__next {
        position: absolute;
        z-index: 1;
 }        // top: 50%
      .slider__prev {
        left: -75px;
        transform: rotate(45deg);
        @include xl {
          left: -10px; } }
      .slider__next {
        right: -110px;
        transform: rotate(225deg);
        @include xl {
          right: -10px; } } }
    &__arrows {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 0;
      width: 100%; } }

  &--banner {
    &__wrap {
      .slider {
        &__prev,
        &__next {
          width: 58px;
          height: 58px;
          background-color: rgba(#000, .5);
          border: none;
          transform: rotate(0deg) translate(0, -50%);
          position: absolute;
          top: 50%;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 20px; } }
        &__prev {
          left: -135px;
          @include xl {
            left: -45px; }
          &::before {
            @include icon-arrow(15, 15, 2, #fff, 45);
            left: 23px; } }
        &__next {
          right: -135px;
          @include xl {
            right: -45px; }
          &::before {
            @include icon-arrow(15, 15, 2, #fff, 225);
            left: 16px; } } } } }

  &--banner-simple {
    &__wrap {
      .slider {
        &__prev,
        &__next {
          @include lg {
            display: block !important; } }
        &__prev {
          left: 0 !important; }
        &__next {
          right: 0 !important; } } } }

  &--brands {
    .slide {
      &__inner {
        @include cover; } }
    &__wrap {
      margin-top: 93px;
      .slider__dots-wrap {
        margin-bottom: 30px;
        top: -75px;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1; }
      .slider__dots {

        bottom: auto;
        position: relative;
        // padding-bottom: 30px
        // height: 90px
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        ul {
          width: 1140px;
          text-align: center;
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          margin-bottom: -17px;
          padding-bottom: 17px;
          height: 52px; }
        li {
          margin-left: 10px;
          margin-right: 10px;
          &.slick-active {
            button {
              color: $c-accent;
              @include bold;
              font-size: 25px;
              &::before {
                content: '';
                visibility: visible;
                opacity: 1;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                background-color: $c-accent;
                height: 1px;
                width: 90%;
                max-width: 55px;
                transition: visibility .2s, opacity .2s; } } } }
        button {
          font-size: 15px;
          line-height: 1.4;
          text-transform: uppercase;
          color: $c-text;
          width: auto;
          height: auto;
          display: inline-block;
          @include roman;
          transition: font-size .3s, color .3s;
          @include hover {
            color: $c-accent; }
          &::before {
            visibility: hidden;
            opacity: 0; } } } }
    &__scroll-icon {
      position: relative;
      // left: 50%
      // bottom: 0
      // top: 100%
      // transform: translate(0, 100%)
      width: 100%;
      .icon {
        font-size: 30px;
        fill: $c-text; } }
    &__scroll-icon-white {
      .icon {
        fill: #fff; } } }

  &--models {
    .slide {
      position: relative;
      &__img {
        margin-bottom: 45px;
        img {
          margin-left: auto;
          margin-right: auto;
          max-width: 100%; } }
      &__title {
        color: #fff;
        font-size: 15px;
        // margin-bottom: 60px
        margin-bottom: 0;
        @include xl {
 }          // margin-bottom: 30px
        @include lg {
          display: none; } }
      &__info {
        background-color: #fff;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 75px;
          background-image: linear-gradient(to top, #fff 20%, #fff, rgba(#fff,0));
          left: 0;
          bottom: 100%; } } }
    &__wrap {
      padding-top: 25px;

      .slider__dots-wrap {
        // margin-bottom: 15px
        margin-bottom: 0;
        top: auto;
        position: relative; }

      .slider__dots {
        position: static;
        height: auto;
        // margin-bottom: 30px
        padding-bottom: 0;
        @include xl {
          margin-bottom: 10px; }
        ul {
          width: 1140px;
          margin-left: auto;
          margin-right: auto;
          white-space: nowrap;
          overflow-y: hidden;
          overflow-x: auto;
          padding-bottom: 17px;
          margin-bottom: -17px; }
        button {
          color: #fff; }
        li {
          margin-left: 25px;
          margin-right: 25px;
          display: inline-block;
          &.slick-active {
            button {
              font-size: 15px;
              padding-top: 5px;
              &::before {
                top: 0; } } } } } }
    // &__dots-wrap
    //   overflow: auto
    //   position: relative
    //   width: 100%
    //   // display: flex
    //   // justify-content: center
 }    //   padding-bottom: 10px



  &--new-items,
  &--reccomend {
    &.slick-initialized {
      .slick-track {
        display: flex; }
      .slick-slide {
        display: flex;
        height: auto;
        float: none;
        > div {
          width: 100%; }
        .item {
          margin-bottom: 0; } } }

    .slide {
      height: 100%;
      .new-item {
        height: 100%; } }
    .slick-slide {
      margin-left: 15px;
      margin-right: 15px; }
    .slick-list {
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 15px;
      padding-top: 15px; }
    &__wrap {}
    &__prev,
    &__next {
      width: 58px;
      height: 58px;
      background-color: rgba(#000, .5);
      border: none;
      transform: rotate(0deg) translate(0, -50%);
      position: absolute;
      top: 50%;
      @include lg {
        display: inline-block !important; }
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 20px; } }
    &__prev {
      left: -135px;
      @include xl {
        left: 0px; }
      &::before {
        @include icon-arrow(15, 15, 2, #fff, 45);
        left: 23px; } }
    &__next {
      right: -135px;
      @include xl {
        right: 0px; }
      &::before {
        @include icon-arrow(15, 15, 2, #fff, 225);
        left: 16px; } } }

  &--testimonials {
    .slick-track {
      display: flex; }
    .slick-slide {
      float: none;
      height: auto;
      > div, .slide, .row {
        height: 100%; } }
    &__wrap {
      &::before {
        content: '';
        width: 50vw;
        right: 100%;
        top: 0;
        bottom: 0;
        position: absolute;
        background-color: #f7f7f7; } }
    &__controls {
      position: absolute;
      bottom: 40px;
      left: 40%;
      z-index: 2;
      @include lg {
        left: 40%;
        bottom: 20px; }
      @include md {
        left: 0;
        bottom: 10px; } }

    &__prev, &__next {
      transform: rotate(0);
      @include lg {
        display: inline-block !important; } } }


  &--partners {
    .slide {
      &__img {
        height: 100px;
        @include vertical; }
      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%; } }
    &__wrap {
      .prev-md, .next-md {
        transform: rotate(0deg) translate(0, -50%);
        position: absolute;
        top: 50%; } }
    &__prev,
    &__next {
      background-color: transparent;
      position: absolute;
      top: 50%;
      @include lg {
        display: inline-block !important; } }
    &__prev {
      left: -90px;
      @include icon-arrow(15, 15, 2, $c-text, 45);
      @include xl {
        left: 0; } }
    &__next {
      right: -90px;
      @include icon-arrow(15, 15, 2, $c-text, 225);
      @include xl {
        right: 0; } } }

  &--spec {
    &__wrap {
      .slide {
        padding-left: 15px;
        padding-right: 15px; } }
    &__prev,
    &__next {
      background-color: transparent;
      position: absolute;
      top: 50%;
      @include lg {
        display: inline-block !important; } }
    &__prev {
      left: -90px;
      @include icon-arrow(15, 15, 2, #fff, 45);
      @include xl {
        left: 0; } }
    &__next {
      right: -90px;
      @include icon-arrow(15, 15, 2, #fff, 225);
      @include xl {
        right: 0; } } }

  &--consultation {
    .slide {
      position: relative;
      font-size: 0;
      &__img {
        @include cover;
        height: 100%;
        @include sm {
          padding-top: 70%;
          height: auto; }
        @include xs {
          padding-top: 100%; } }
      &__name {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(#000, .5);
        padding: 30px;
        .text {
          margin-bottom: 10px; } }
      &__top-title {
        height: 58px;
        background-color: rgba(#000, .5);
        position: absolute;
        display: flex;
        align-items: center;
        padding-left: 30px;
        width: 100%;
        padding-right: 116px;
        top: 0;
        left: 0;
        z-index: 0; } }
    &__prev,
    &__next {
      width: 58px;
      height: 58px;
      // background-color: rgba(#000, .5)
      background-color: transparent;
      border: none;
      transform: rotate(0deg) translate(0, 0);
      position: absolute;
      top: 0;
      @include lg {
        display: inline-block !important; }
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 20px; } }
    &__prev {
      right: 0;
      transform: translate(-100%, 0) rotate(0);
      &::before {
        @include icon-arrow(15, 15, 2, #fff, 45);
        left: 23px; } }
    &__next {
      right: 0;
      &::before {
        @include icon-arrow(15, 15, 2, #fff, 225);
        left: 16px; } } } }


.slide {
  @include cover;
  &__inner {
    height: 100%;
    position: relative; }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    video {
      min-height: 100%;
      min-width: 100%; } } }

.models-slider {
  @include cover;
  text-align: center;
  position: relative;
  @include lg {
    background-position: 50% -100px; }
  &::before {
    content: '';
    position: absolute;
    height: 350px;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to bottom, #000 20%, rgba(#000,.5), rgba(#000,0)); } }

.new-items-slider {
  padding-top: 40px;
  &.has-pt-sm {
    padding-top: 20px; }
  @include md {
    padding-top: 0; } }

.banner-slider {
  position: relative;
  font-size: 0;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 150px;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: url('../img/banner-slider/bg-top.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0; }
  .slide {
    height: calc(100vh - 90px); // header height
    @include lg {
      height: calc(100vh - 45px); }
    @include sm {
      height: 50vh; }

    .home &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      background-color: rgba(#000, .3); }
    .home & {
      height: calc(100vh - 90px); // header height
      @include lg {
        height: calc(100vh - 45px); } } }

  &--simple {
    &::before {
      display: none; } } }

.thumbnail-slider {
  @include sm {
    .slick-slide {
      margin-left: 5px;
      margin-right: 5px; }
    .slick-list {
      margin-left: -5px;
      margin-right: -5px;
      padding-bottom: 5px;
      padding-top: 5px; } }
  &__wrap {
    padding-top: 10px;
    padding-bottom: 10px; }
  &__prev,
  &__next {
    position: absolute;
    left: 50%;
    display: inline-block;
    background-color: transparent;
    @include sm {
      left: auto;
      top: 50%; } }
  &__prev {
    @include icon-arrow(8, 8, 1, #000, 135);
    top: -5px;
    @include sm {
      @include icon-arrow(8, 8, 1, #000, 45);
      transform: translate(0, -50%) rotate(45deg);
      left: -10px; } }
  &__next {
    @include icon-arrow(8, 8, 1, #000, -45);
    bottom: -5px;
    @include sm {
      right: -10px;
      @include icon-arrow(8, 8, 1, #000, 225);
      transform: translate(0, -50%) rotate(225deg); } } }

.thumbnail-slide {
  display: none;
  position: relative;
  &::before {
    content: '';
    @include coverdiv;
    border: 1px solid  $c-accent;
    background-color: rgba($c-accent, .45);
    pointer-events: none;
    display: none; }
  &.is-active {
    &::before {
     display: block; } }
  &__img {
    padding-top: 60%;
    display: block;
    @include cover;
    font-size: 0;
    @include lg {
      padding-top: 75%; }
    @include sm {
      padding-top: 50%; } } }

.prev-md, .next-md {
  width: 38px;
  height: 38px;
  background-color: rgba(#000, .5);
  border: none;


  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 11px; } }

.prev-md {
  right: 0;
  // transform: translate(-100%, 0) rotate(0)
  &::before {
    @include icon-arrow(15, 15, 2, #fff, 45);
    left: 14px; } }
.next-md {
  right: 0;
  &::before {
    @include icon-arrow(15, 15, 2, #fff, 225);
    left: 8px; } }
