.s-scedule {
  padding-bottom: 40px;
  @include cover;
  &__right {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 40px;
    img {
      max-width: 100%; } } }

.scedule {
  $this: &;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &__top {
    background-color: #f4f4f4;
    padding: 30px 20px 25px;
    text-align: center;
    margin-bottom: 20px;
    @include sm {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px; } }
  &__scedule {
    border: 1px solid  $c-border;
    padding: 28px 20px 10px;
    @include sm {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px; } }
  &__title {
    margin-bottom: 15px; }
  &__phone {
    font-size: 30px;
    margin-bottom: 25px;
    display: inline-block;
    @include medium;
    @include xs {
      font-size: 24px; }
    .icon {
      fill: $c-accent;
      font-size: 21px;
      @include xs {
        font-size: 18px; } } }
  &__contacts {
    display: flex;
    align-items: center;
    @include lg {
      display: block; } }

  &--hor {
    #{$this}__scedule {
      box-shadow: 0px 0px 9.9px 1.1px rgba(0, 0, 1, 0.14);
      margin-bottom: 30px;
      max-width: 385px;
      @include md {
        margin-bottom: 15px; } }
    #{$this}__btn {
      margin-right: 20px;
      @include lg {
        margin-right: 0;
        margin-bottom: 15px; } }
    #{$this}__phone {
      margin-bottom: 0; } } }


.scedule-list {
  &__list {
    @include clr;
    @include light;
    font-size: 16px;
    color: #2c2c2c; }
  &__title {
    float: left;
    margin-bottom: 10px;
    width: 50%;
    border-bottom: 1px solid $c-border; }
  &__text {
    float: right;
    margin-bottom: 10px;
    width: 50%;
    text-align: right;
    border-bottom: 1px solid $c-border; } }

.services {
  &__title {
    background-color: #2c2c2c;
    padding-top: 30px;
    padding-bottom: 30px; }
  &__items {
    padding-top: 40px;
    padding-bottom: 40px; } }

.services-item {
  $this: &;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 18px; }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    border: 1px solid  $c-border;
    padding-right: 78px;
    position: relative;
    @include md {
      display: block;
      padding-left: 15px; }
    @include sm {
      padding-right: 40px;
      .h4, h4 {
        margin-bottom: 10px; } }
    @include xs {
      padding-left: 10px; }
    &.is-active {
      #{$this}__icon {
        @include before {
          @include icon-arrow(12, 12, 2, $c-text, -45); } } } }
  &__icon {
    width: 68px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    border-left: 1px solid  $c-border;
    @include sm {
      width: 35px; }
    @include before {
      transition: transform .3s;
      @include icon-arrow(12, 12, 2, $c-text, -135); } }

  &__content {
    background-color: #f7f7f7;
    border: 1px solid  $c-border;
    border-top: 0;
    cursor: default;
    display: none; }
  &__item {
    @include light;
    &:not(:last-child) {
      border-bottom: 1px solid  $c-border; } }
  &__inner-title {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    @include md {
      padding-left: 15px;
      padding-right: 15px; }
    @include xs {
      padding-left: 10px;
      padding-right: 10px; }
    span {
      display: inline-block;
      position: relative;
      padding-right: 20px;
      @include before {
        transition: transform .3s;
        display: inline-block;
        @include icon-arrow(8, 8, 2, $c-text, -135);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%) rotate(-135deg); } }
    &.is-active {
      span {
        @include before {
          @include icon-arrow(8, 8, 2, $c-text, -45);
          transform: translate(0, -50%) rotate(-45deg); } } } }
  &__inner-content {
    padding-left: 35px;
    display: none; }
  &__inner-item {
    padding-top: 5px;
    padding-bottom: 5px; } }

.service-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 7.52px 0.48px rgba(0, 0, 1, 0.1);
  margin-bottom: 30px;
  height: calc(100% - 30px);
  &__img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 15px;
    padding-top: 40%; }
  &__content {
    padding: 25px;
    padding-top: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @include md {
      padding: 15px;
      padding-top: 0; } }
  &__title {
    margin-bottom: 10px; }
  &__text {
    margin-bottom: 15px;
    flex-grow: 1; }
  &__btns {
    display: flex;
    align-items: center;
    @include lg {
      display: block; } }
  &__btn {
    width: 100%;
    &:not(:last-child) {
      margin-right: 15px;
      @include lg {
        margin-right: 0;
        margin-bottom: 15px; } }
    &.btn {
      .btn__title {
        width: calc(100% - 50px); } } } }
