.popup {
  $this: &;
  @include coverdiv;
  position: fixed;
  z-index: 20;
  background-color: rgba(#000, .8);
  @include vertical;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s, visibility .3s;
  @include xs {
    padding-left: 15px;
    padding-right: 15px; }
  &.is-active {
    visibility: visible;
    opacity: 1;
    .popup__inner {
      transform: scale(1); } }
  &__icon {
    margin-bottom: 20px;
    .icon {
      font-size: {}
      height: 70px;
      width: 70px;
      fill: $c-accent; } }
  &__inner {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    background-color: #fff;
    padding: 35px 55px 40px;
    position: relative;
    max-width: 430px;
    transform: scale(0);
    transition: transform .3s;
    @include xs {
      max-width: none;
      padding: 30px 15px; } }
  &__title {
    margin-bottom: 10px; }
  &__text {
    margin-left: -55px;
    margin-right: -55px;
    padding-right: 55px;
    padding-left: 55px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f1f1f1;
    font-size: 14px;
    @include light;
    color: #7c7c7c;
    &:not(:last-child) {
      margin-bottom: 20px; }
    @include xs {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: -15px;
      margin-right: -15px; } }
  &__close {
    color: #818181;
    @include icon-close(15, 2, currentColor);
    position: absolute;
    top: 15px;
    right: 15px; }

  &--sm {
    #{$this}__inner {
      padding-bottom: 0; }
    #{$this}__title {
      margin-bottom: 20px; } }

  &--rating {
    font-size: 15px;
    @include sm {
      padding-left: 15px;
      padding-right: 15px; }
    &__inner {
      display: inline-flex;
      padding: 0;
      max-width: 770px;
      width: 100%;
      @include sm {
        display: inline-block;
        max-width: none;
        width: auto; }
      @include xs {
        padding: 0;
        width: calc(100% - 30px); } }
    &__close {
      color: #fff;
      @include sm {
        top: 5px;
        right: 5px; } }
    &__img,
    &__content {
      display: flex;
      width: 50%;
      flex-direction: column;
      text-align: left;
      @include sm {
        display: block;
        width: 100%; } }
    &__img {
      @include cover;
      @include sm {
        display: none; } }
    &__top {
      padding: 30px 10px;
      text-align: center;
      background-color: $c-accent;
      @include sm {
        padding: 20px 10px; } }
    &__form {
      padding: 25px;
      @include sm {
        padding: 15px; }
      @include xs {
        padding: 10px; } } } }
