.hero {
  background-color: #353535;
  font-size: 0;
  position: relative;
  overflow: hidden;
  @include cover;
  &.is-desctop {
    @include xs {
      display: none; } }
  &__slider {
    .slide {
      height: calc(100vh - 90px); // header height
      @include lg {
        height: calc(100vh - 45px); } } }
  &__title {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .hero__subttl {
      margin-bottom: 0; }
    .title {
      margin-bottom: 5px; }
    &::before {
      content: '';
      position: absolute;
      width: 53px;
      height: 1px;
      background-color: #fff;
      bottom: 0;
      left: 0; } }
  &__subttl {
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px; }
  &__btn-right {
    height: 100%;
    display: flex;
    align-items: center;
    @include md {
      padding-top: 15px;
      display: block; } }
  &__block {
    z-index: 1;
    position: relative; }
  &__bg {
    @include coverdiv;
    @include cover;
    z-index: 0;
    video {
      // min-width: 100%
      // min-height: 100%
      width: 100%;
      height: 100%;
      object-fit: cover; }
    &::before {
      content: '';
      @include coverdiv;
      background-color: rgba(#000, .5); } }

  &--sm {
    padding-top: 40px;
    padding-bottom: 40px;
    @include lg {
      padding-top: 80px;
      padding-bottom: 80px; } }
  &--lg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 90px); // header heigth
    padding-bottom: 85px;
    padding-top: 120px;
    @include lg {
       height: calc(100vh - 45px); }
    @include md {
      padding-top: 50px;
      padding-bottom: 50px; } }
  &--center {
    justify-content: center;
    padding-top: 85px;
    padding-bottom: 85px; }
  &--xs {
    padding-bottom: 25px;
    padding-top: 25px;
    text-align: center;
    .hero {
      &__title {
        padding-bottom: 0;
        &::before {
          display: none; } }
      &__breadcrumbs {
        margin-bottom: 15px; } } } }
