.brand-nav {
  background-color: #191919;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  @include md {
    display: none; }
  &__nav {
    max-width: 100%; }
  &__inner {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center; }
  &__title {
    font-size: 15px;
    color: #fff;
    padding-right: 20px;
    position: relative;
    border-right: 1px solid  #464646;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 120px; } }
