.charge-info {
  &__title {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 25px;
    @include md {
      margin-bottom: 0; } } }

.charge-info-block {
  position: relative;
  margin-bottom: 3px;
  &:first-child {
    .charge-info-block__left {
      padding-top: 135px;
      @include md {
        padding-top: 60px; } } }
  &__title {
    margin-bottom: 10px;
    .h2, h2 {
      margin-bottom: 5px; } }
  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: 30px;
        @include md {
          margin-bottom: 15px; }
        @include sm {
          margin-bottom: 10px; } }
      &:last-child {
        margin-bottom: 10px; } } }
  &__left {
    padding-top: 65px;
    min-height: 762px;
    @include md {
      min-height: 0;
      margin-bottom: 15px;
      padding-top: 30px; } }
  &__right {
    @include cover;
    position: absolute;
    width: 45%;
    // padding-top: 100%
    height: 100%;
    top: 0;
    right: 0;
    @include md {
      position: static;
      padding-top: 45%;
      height: auto;
      width: auto; } } }
