.new-item {
  box-shadow: 0px 0px 12.92px 4.08px rgba(0, 0, 1, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &__title {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    @include hover {
      a {
        color: $c-accent; } }
    a {
      transition: color .3s; }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 53px;
      height: 1px;
      background-color: $c-text; } }
  &__img {
    @include cover;
    // background-size: contain
    background-color: #e8e8e8;
    // padding-top: 45px
    // padding-bottom: 45px
    padding-top: 80%;
    img {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; } }
  &__list {
    margin-bottom: 10px; }
  &__top {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    flex-grow: 1; }
  &__btns {
    display: flex;
    height: 64px;
    @include md {
      flex-direction: column;
      height: auto;
      .btn {
        width: auto;
        &:first-child {
          &::before {
            display: none; } } } }
    .btn {
      width: 50%;
      position: relative;
      &:first-child {
        &::before {
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          background-color: #e1e1e1;
          top: 0;
          right: 0; } } } } }
