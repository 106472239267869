.tech-info {
  $this: &;
  color: $c-text;
  &__inner {
   font-size: 0;
   margin-left: -15px;
   margin-right: -15px; }
  &__item {
    position: relative;
    color: inherit;
    display: inline-block;
    vertical-align: top;
    width: 33.33333%;
    padding-left: 70px;
    margin-bottom: 30px;
    @include sm {
      padding-left: 40px;
      &::before {
        height: 100%;
        top: 0; } }
    @include xs {
      width: 50%;
      margin-bottom: 15px;
      &:nth-child(2n -2) {
        &::before {
          display: none; } }
      &:nth-child(3) {
        &::before {
          display: block; } }
      &:nth-child(4n-4), &:nth-child(1) {
        #{$this}__icon {
          left: 10px; } } }


    &:nth-child(3n -3) {
      &::before {
        display: none; } }
    &:nth-child(4n-4), &:nth-child(1) {
      padding-left: 40px;
      #{$this}__icon {
        left: 0; } }
    &::before {
      content: '';
      background-color: currentColor;
      width: 1px;
      height: 48px;
      position: absolute;
      top: 5px;
      right: 0px; } }

  &__icon {
    position: absolute;
    top: 5px;
    left: 30px;
    @include sm {
      left: 10px;
      .icon {
        font-size: 22px; } }
    .icon {
      fill: currentColor;
      font-size: 32px; }
    .icon-engine {
      font-size: 36px; }
    .icon-speedometer {
      position: relative;
      top: -2px; } }
  &__subttl {
    font-size: 15px;
    color: #bfbfbf;
    @include sm {
      font-size: 14px; }
    @include xs {
      font-size: 12px; } }
  &__title {
    white-space: nowrap;
    @include sm {
      font-size: 24px !important; }
    @include xs {
      font-size: 21px !important; } }


  &--white {
    color: #fff; }
  &--row {
    #{$this}__icon {
      left: 15px !important; }
    #{$this}__item {
      width: 16.666667%;
      padding-left: 60px;
      @include lg {
        width: 33.33333%;
        display: -webkit-inline-flex;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;

        &:nth-child(3) {
          &::before {
            display: none; } }
        &:nth-child(4n-4) {
          padding-left: 0; } }

      @include xs {
        width: 50%; }
      &:nth-child(3n -3) {
        &::before {
          display: block; } }
      &:nth-child(4n-4) {
        padding-left: 60px; }
      &:last-child {
        &::before {
          display: none; } } }
    #{$this}__item-inner {
      @include lg {
        position: relative;
        padding-left: 60px;
        width: 190px; }
      @include sm {
        padding-left: 50px; } } } }
