.features {
  &__top {
    background-color: #f7f7f7;
    padding-top: 40px;
    &.is-simple {
      background-color: #fff;
      padding-top: 30px; } }
  &__bottom {
    background-color: #242424; }
  &__inner {
    display: flex;
    flex-wrap: wrap; }
  &__text {
    margin-bottom: 30px; } }

.feature {
  font-size: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 25%;
  @include md {
    width: 50%;
    &:nth-child(1) {
      background-color: #2a2a2a; }
    &:nth-child(2) {
      background-color: #242424; }
    &:nth-child(3) {
      background-color: #242424; }
    &:nth-child(4) {
      background-color: #2a2a2a; } }
  @include xs {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    background-color: #242424 !important; }
  &:nth-child(odd) {
    background-color: #2a2a2a;
    @include xs {
      background-color: #2a2a2a !important; } }
  &__icon {
    border: 1px solid  $c-accent;
    border-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    margin-bottom: 30px;
    .icon {
      fill: $c-accent;
      font-size: 40px; } }
  &__title {
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 10px; }
  &__text {
    padding-left: 35px;
    padding-right: 35px; } }

.feature-simple {
  $this: &;
  text-align: center;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  &__icon {
    color: $c-accent;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-bottom: 1px solid $c-accent;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      fill: currentColor;
      font-size: 40px; } }
  &__title {
    margin-bottom: 10px; }

  &--lg {
    #{$this}__icon {
      border-radius: 50%;
      border: 1px solid  $c-border;
      width: 94px;
      height: 94px; } }
  &--hor {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 15px;
    height: auto;
    #{$this}__icon {
      margin-left: 0;
      margin-right: 20px;
      @include md {
        margin-right: 10px; } } } }

