.social {
  &__item {
    display: inline-block;
    vertical-align: top;
    &:not(:last-child) {
      margin-right: 5px; } }
  &__link {
    display: flex;
    background-color: $c-accent;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    transition: background-color .3s;
    @include hover {
      background-color: #282828; }
    .icon {
      fill: #fff;
      font-size: 18px; }
    .icon-facebook {
 } }      // font-size: 14px

  &--sm {
    .social__link {
      width: 28px;
      height: 28px; } } }
