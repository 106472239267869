.aside {
  background-color: #fff;
  height: 100%;
  .row {
    height: 100%;
    display: block;
    @include md {
      display: flex; } }
  .aside-banner {
    @include sm {
      display: none; } }
  @include mdmin {
    display: block !important; }
  @include md {
    display: none; }
  @include sm {
 }    // margin-bottom: 30px
  &__block {
    padding: 20px 25px 45px;
    margin-bottom: 30px;
    border: 1px solid  $c-border;
    @include hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 1, 0.15); } } }


.aside-banner {
  @include cover;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 30px;
  padding-top: 120%;
  position: relative;
  @include sm {
    padding: 20px;
    padding-top: 30%; }
  @include xs {
    padding-top: 60%; }
  &::before {
    content: '';
    @include coverdiv;
    z-index: 0;
    background-image: linear-gradient( 0deg, rgb(0,0,0) 0%, rgba(0,0,0,0) 100%); }
  &__block {
    position: relative;
    z-index: 1; }
  &__subttl {
    margin-bottom: 5px; }
  &__contact {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    @include bold;
    text-transform: uppercase; }
  &__btn {
    font-size: 0;
    padding-top: 12px; } }

.aside-show-btn {
  font-size: 0;
  margin-bottom: 30px;
  display: none;
  @include md {
    display: block; } }

.aside-services {
  $this: &;
  @include mdmin {
    display: block !important;
    position: sticky;
    max-height: calc(100vh - 114px);
    overflow: auto;
    &.js-sticky-column {
      top: 114px; } }
  @include md {
    display: none;
    margin-bottom: 30px; }
  &__title {
    text-align: center; }
  &__service {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 65px;
    padding: 12px 15px;
    padding-right: 30px;
    border-bottom: 1px solid $c-border;
    transition: color .3s, background-color .3s;
    @include hover {
      @extend #{$this}__service.is-active; }

    &::before {
      content: '';
      @include icon-arrow(14, 14, 1, currentColor, 45);
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%) rotate(225deg); }
    &.is-active {
      color: #fff;
      background-color: $c-accent; } } }
