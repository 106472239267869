.selects {
  &__select {
    margin-bottom: 15px; }
  &__btn {
    font-size: 0; } }

.custom-select {
  position: relative;
  select {
    visibility: hidden;
    opacity: 0;
    position: absolute; }
  &.is-open {
    .custom-select {
      &__panel {
        visibility: visible;
        opacity: 1; } } }
  &.has-placeholder {
    .custom-select__option {
      &:first-child {
        display: none; } } }
  &__opener {
    height: 33px;
    border: 1px solid  #e8e8e8;
    @include light;
    font-size: 13px;
    padding: 7px 10px;
    padding-right: 35px;
    @include text-overflow;
    position: relative;
    color: #8d8d8d;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      @include icon-arrow(7, 7, 1, $c-text, -45);
      position: absolute;
      top: 11px;
      right: 15px;
      pointer-events: none; } }
  &__panel {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    max-height: 300px;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #303030;
    z-index: 1;
    padding: 10px;
    border: 1px solid #e7e7e7;
    background-color: #fafafa;
    z-index: 1;
    padding: 10px;
    box-shadow: 0px 4px 5px #0003; }
  &__option {
    @include light;
    font-size: 13px;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    @include text-overflow;
    vertical-align: middle;
    color: #2c2c2c;
    @include hover {
      color: $c-accent; }
    label {
      pointer-events: none; }
    input[type="checkbox"] {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      top: 3px; }
    .color-square {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      top: 2px;
      height: 12px;
      width: 12px;
      background-color: red; }
    &.is-selected {
      color: $c-accent; } }
  &__panel-inner {
    max-height: 300px; }
  &__panel-item {
    position: relative;
    input {
      height: 32px;
      padding: 5px;
      padding-left: 25px;
      width: 100%;
      background-color: #fff;
      font-family: inherit;

      border: 1px solid #e8e8e8;
      @include placeholder {
        color: #979797;
        font-size: 15px;
        font-family: inherit;
        @include light; } }
    .icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 20px;
      fill: currentColor;
      pointer-events: none; } } }

.select {
  &--multiple {
    .custom-select {
      &__opener {
        height: 48px;
        padding: 13px 20px;
        padding-right: 40px;
        font-size: 15px;
        color: $c-text;
        &::before {
          top: 17px;
          right: 20px; } }
      &__panel {
        padding: 20px;
        padding-top: 50px;
        max-height: none; }
      &__panel-item {
        position: absolute;
        width: 100%;
        top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 1;
        left: 0; }
      &__option {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 15px; } } }
  &--input {
    .custom-select {
      text-align: left;
      &__opener {
        border-width: 0;
        border-bottom-width: 1px;
        border-bottom-color: #202020;
        padding-left: 20px;
        padding-left: 0; } } } }


.items-top-select {
  display: flex;
  align-items: center;
  label {
    margin-right: 12px;
    font-size: 15px;
    @include lg {
      display: none; } }
  .select {
    width: 100%; } }
