.model-tabs {
  &__tabs {
    background-color: #2c2c2c; } }

.model-tabs-list {
  $this: &;
  &__list {
    display: flex;
    @include md {
      display: block;
      font-size: 0; }
    @include xs {
      margin-left: -15px;
      margin-right: -15px; } }
  &__item {
    display: inline-block;
    flex-grow: 1;
    @include md {
      width: 50%;
      vertical-align: top; }
    @include xs {
      width: 100%; } }
  &__link {
    display: block;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    @include bold;
    font-size: 15px;
    border-bottom: 2px solid transparent;
    transition: border-color .3s, background-color .3s;
    padding: 20px 10px;
    @include sm {
      border-bottom: 1px solid #646464; }
    @include xs {
      padding-top: 10px;
      padding-bottom: 10px; }
    sup {
      vertical-align: super;
      font-size: 10px; }
    @include hover {
      @extend .model-tabs-list__link.is-active; }
    &.is-active {
      border-bottom-color: $c-accent;
      background-color: #353535;
      #{$this}__icon {
        @include icon-arrow(8, 8, 1, currentColor, 135);
        top: 1px; } } }
  &__icon {
    display: inline-block;
    @include icon-arrow(8, 8, 1, currentColor, -45);
    position: relative;
    margin-left: 8px;
    top: -3px;
    transition: transform .3s; } }

.complectation-list {
  $this: &;
  // &__list
  //   +md
  //     margin-bottom: 20px
  &__list {
    font-size: 0; }
  &__item {
    @include thin;
    font-size: 15px;
    position: relative;
    padding-left: 25px;
    text-align: left;
    .icon {
      font-size: 15px;
      margin-right: 12px;
      position: absolute;
      top: 2px;
      left: 0; }
    &:not(:last-child) {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 20px; } } }

  &--lg {
    #{$this}__item {
      display: inline-block;
      vertical-align: top;
      width: 33.33333%;
      padding-right: 10px;
      @include md {
        width: 50%; }
      @include xs {
        width: 100%; } } } }



.model-tab-item {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #f7f7f7;
  display: none;
  @include xs {
    padding-top: 20px;
    padding-bottom: 20px; }
  &.is-active {
 }    // display: block
  &__title {
    margin-bottom: 30px; }
  &__img {
    position: relative;
    padding-top: 75%;
    iframe {
      @include coverdiv; }
    .spritespin-instance {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
      cursor: grab; } }
  &__icon {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: rgba(#000, .5);
    padding: 15px;
    @include sm {
      padding: 5px; }
    .icon {
      font-size: 40px;
      fill: #fff;
      @include sm {
        font-size: 30px; } } } }

.tech-table {
  $this: &;
  width: calc(100% + 15px);
  @include sm {
    width: 100%; }
  &__tr {
    border-top: 1px solid  $c-border;
    border-bottom: 1px solid  $c-border;
    background-color: #efefef;
    &:nth-child(even) {
      background-color: transparent; } }
  &__th {
    font-weight: 400;
    text-align: left;
    @include bold;
    font-size: 15px;
    padding: 10px;
    padding-left: 35px;
    width: 40%;
    @include lg {
      width: 50%; } }
  &__td {
    @include thin;
    font-size: 15px;
    padding: 10px;
    width: 60%;
    @include lg {
      width: 50%; } }

  &--left {
    border-left: 1px solid  $c-border;
    margin-right: -15px;
    @include sm {
      margin-right: 0;
      border-right: 1px solid  $c-border; } }

  &--right {
    border-right: 1px solid  $c-border;
    margin-left: -15px;
    @include sm {
      margin-left: 0;
      border-left: 1px solid  $c-border;
      #{$this}__tr {
        background-color: #efefef;
        &:nth-child(odd) {
          background-color: transparent; } } } } }
