.s-testimonials {
  overflow: hidden; }


.testimonial {
  &__img {
    height: 100%;
    padding-top: 60%;
    @include cover; }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f7f7f7;
    position: relative;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    @include md {
      padding-right: 15px;
      padding-bottom: 70px; } }
  &__text {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__person {
    position: relative;
    padding-left: 55px;
    max-width: 275px;
    &::before {
      content: '';
      position: absolute;
      top: .2em;
      left: 0;
      z-index: 1;
      background-color: $c-accent;
      height: 1px;
      width: 35px; } }
  &__person-title {
    @include bold; }
  &__person-subttl {
    @extend .text--gray; } }
