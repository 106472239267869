.nav {
  &__item, li {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    &.current-menu-item {
      color: $c-accent;
      a {
        color: $c-accent; } }
    @include lg {
      display: block;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 15px; } }
  &__link, a {
    font-size: 15px;
    transition: color .3s;
    @include hover {
      color: #828282; }
    @include lg {
      font-size: 18px; } }

  &--brands {
    overflow: hidden;
    max-width: 100%;
    &__list, ul {
      // width: 1040px
      // overflow: auto
      // white-space: nowrap
      // margin-bottom: -17px
 }      // padding-bottom: 17px
    &__link, a {
      text-transform: uppercase;
      @include light;
      font-size: 15px;
      color: #fff;
      @include lg {
        font-size: 15px; } }
    &__item, li {
      @include lg {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0;
        width: 10%; } } }
  &--footer {
    &__item, li {
      display: block;
      margin-left: 0;
      margin-right: 0;
      &:not(:last-child) {
        margin-bottom: 22px;
        @include md {
          margin-bottom: 12px; }
        @include sm {
          margin-bottom: 5px; } } }
    &__link, a {
      @include lg {
        font-size: 15px; }
      @include xs {
        font-size: 14px; } } } }

.nav-brands {
  // overflow: hidden
  &__list {
    // white-space: nowrap
    overflow: auto;
    // margin-bottom: -17px
    padding-top: 20px;
    padding-bottom: 37px; }

  &__item {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 30px; } }
  &__link {
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    transition: color .3s;
    @include hover {
      color: $c-accent; }
    &::before {
      content: '';
      height: 1px;
      position: absolute;
      top: 0;
      width: 53px;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: $c-accent;
      display: none; }
    &.is-active {
      font-size: 25px;
      color: $c-accent;
      @include bold;
      &::before {
        display: block; } } } }
