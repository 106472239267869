.banks {
  padding-top: 40px; }

.bank-info-wrap {
  display: none; }


.bank-item {
  display: block;
  padding-top: 100%;
  border: 1px solid  $c-border;
  position: relative;
  transition: border-color .3s;
  margin-bottom: 30px;
  @include xs {
    padding-top: 30%;
    img {
      // max-width: 50%
      max-height: 70%; } }
  @include hover {
    border-color: $c-accent; }
  img {
    @include vcenter;
    max-width: 70%; }
  &__toggle {
    bottom: 0;
    right: 0;
    display: inline-block;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 1, 0.11);
    @include icon-close(36, 2, #adadad);
    position: absolute;

    &.is-active {
      &::after {
        display: none; } }
    &::before,
    &::after {
      height: 12px;
      content: '';
      position: absolute;
      @include vcenter; }
    &::after {
      transform: rotate(90deg);
      top: 11px;
      left: 16px; }
    &::before {
      transform: rotate(0deg);
      top: 11px;
      left: 16px; } } }

.bank-item-info {
  border: 1px solid  $c-border;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 30px;
  &::before,
  &::after {}
  @include before {
    @include arr(35,20, $c-border, t);
    position: absolute;
    bottom: 100%;
    @include xs {
      left: 50% !important;
      transform: translate(-50%, 0); } }
  @include after {
    @include arr(33,18, #fff, t);
    position: absolute;
    bottom: 100%;
    @include xs {
      left: 50% !important;
      transform: translate(-50%, 0); } }
  &.is-left {
    @include before {
      left: 14%; }
    @include after {
      left: calc(14% + 1px); } }
  &.is-right {
    @include before {
      left: 83%; }
    @include after {
      left: calc(83% + 1px); } }
  &.is-center {
    @include before {
      left: 48%; }
    @include after {
      left: calc(48% + 1px); } }
  &__text {
    padding: 30px;
    padding-bottom: 10px;
    @include md {
      padding: 15px; }
    p {
      margin-bottom: 10px; }
    strong {
      @include bold; }
    ul {
      list-style-type: disc;
      margin-bottom: 10px;
      padding-left: 20px; }
    ol {
      margin-bottom: 10px; } }
  &__table {
    margin-bottom: 30px;
    padding: 30px;
    @extend .text;
    @extend .text--gray;
    @include md {
      padding: 15px;
      table {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px); } }
    @include sm {
      overflow: auto; }
    strong {
      @include bold; }
    table {
      margin-right: -30px;
      margin-left: -30px;
      width: calc(100% + 60px);
      @include light;
      margin-bottom: 10px;
      tr {
        &:first-child {
          background-color: #fafafa;
          td {
            @include bold; } } }
      td, th {
        border: 1px solid  $c-border;
        padding: 10px; } }
    ul {
      list-style-type: disc;
      padding-left: 20px;
      margin-bottom: 10px; }
    ol {
      margin-bottom: 10px;
      list-style-type: decimal;
      padding-left: 20px; } }


  &__calc {
    padding-left: 40px;
    padding-right: 40px;
    @include md {
      padding-left: 30px;
      padding-right: 30px; }
    @include sm {
      padding-left: 15px;
      padding-right: 15px; } } }
