.faq-block {
  background-color: #f6f6f6;
  padding: 65px 55px;
  @include xl {
    padding: 55px; }
  @include lg {
    padding: 30px; }
  @include md {
    padding: 30px 20px; }
  @include sm {
    padding: 25px 15px; } }

.faq {
  &__item {
    &:not(:last-child) {
      margin-bottom: 8px; } }
  &__title {
    display: block;
    width: 100%;
    text-align: left;
    background-color: #fff;
    border: 1px solid  $c-border;
    padding: 15px 20px;
    padding-right: 50px;
    position: relative;
    @include bold;
    @include sm {
      padding: 10px 15px;
      padding-right: 30px; }
    &::before {
      content: '';
      @include icon-arrow(12, 12, 1, $c-accent, -45);
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%) rotate(-45deg);
      @include sm {
        right: 10px; } }
    &.is-active {
      border-bottom: 0; } }
  &__content {
    background-color: #fff;
    padding: 15px 20px;
    padding-right: 50px;
    border: 1px solid  $c-border;
    border-top: 0;
    display: none;
    @include sm {
      padding: 10px 15px;
      padding-right: 30px; } } }
