.two-banners {
  display: flex;
  @include sm {
    display: block; } }

.banner-half {
  width: 50%;
  @include cover;
  padding-top: 32%;
  position: relative;
  @include sm {
    width: auto;
    padding-top: 60%; }
  @include xs {
    padding-top: 100%; }
  &::before {
    content: '';
    @include coverdiv;
    z-index: 1;
    background-color: rgba(#000, .4); }
  &:hover {
    .banner-half__btn {
      max-height: 50px;
      overflow: visible; } }
  &__block {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: percentage(75/960);
    bottom: percentage(141/620);
    max-width: 385px;
    @include md {
      max-width: 280px; } }
  &__title {
    margin-bottom: 15px;
    .title {
      margin-bottom: 10px; } }
  &__btn {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s; }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
