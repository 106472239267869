.form {
  $this: &;
  &__btn {
    font-size: 0; }
  &__field {
    margin-bottom: 25px;
    &.has-mb-lg {
      margin-bottom: 40px; } }
  &__gray-block {
    background-color: #f8f8f8;
    padding-bottom: 40px;
    padding-top: 40px; }
  &__block {
    padding-top: 40px;
    padding-bottom: 40px;
    @include lg {
      padding-bottom: 20px;
      padding-bottom: 20px; } }

  &--consultation {
    .form {
      &__row {
        display: flex;
        margin-bottom: 40px;
        @include lg {
          display: block; } }
      &__field {
        margin-bottom: 0;
        @include lg {
          &:not(:last-child) {
            margin-bottom: 10px; } }
        &:not(:last-child) {
          margin-right: 60px; } } } }
  &--popup {
    #{$this}__field {
      &:not(:last-child) {
        margin-bottom: 20px; }
      &:last-child {
        margin-bottom: 40px; } } }
  &--rating {
    #{$this}__field {
      &:first-child {
        margin-bottom: 10px; } } } }

.input {
  @include light;
  input {
    height: 32px;
    border-bottom: 1px solid  #202020;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 282px;
    font-family: inherit;
    @include placeholder {
      color: #979797;
      font-size: 15px;
      font-family: inherit;
      @include light; } }

  &--full {
    input {
      width: 100%;
      min-width: 0; } }
  &--gray {
    input {
      border-bottom-color: $c-border; } }
  &--md {
    font-size: 15px; }
  &--dynamic {
    position: relative;
    input {
      padding-left: 20px; }
    .input__icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      .icon {
        fill: #979797;
        font-size: 14px; } }
    &::before {
      content: '';
      display: inline-block;
      @include icon-arrow(10, 10, 1, #979797, -45);
      transform: translate(0, -50%) rotate(-45deg);
      position: absolute;
      top: 50%;
      right: 0; } } }


.textarea {
  @include light;
  textarea {
    height: 32px;
    overflow: hidden;
    border: 0;
    outline: none;
    resize: none;
    border-bottom: 1px solid  #202020;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 282px;
    font-family: inherit;
    @include placeholder {
      color: #979797;
      font-size: 15px;
      font-family: inherit;
      @include light; } }

  &--full {
    textarea {
      width: 100%; } }
  &--gray {
    textarea {
      border-bottom-color: $c-border; } }
  &--lg {
    font-size: 15px;
    textarea {
      width: 100%;
      border: 1px solid  $c-border;
      padding: 10px 15px;
      min-height: 95px;
      min-width: 0; } } }



.rating-wrap {
  @include clr;
  color: #979797;
  font-size: 15px;

  label {
    float: left;
    margin-top: 8px; }
  .icon {
    fill: currentColor;
    font-size: 35px; } }

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  .icon {
    fill: #fbcf3a; } }


.rating {
  float: right;
  position: relative;
  @include sm {
    float: left; }

  input {
    display: none; }
  label {
    display: inline-block;
    position: relative;
    float: right;
    margin-top: 0;
    cursor: pointer; } }
