.section {
  background-color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
  position: relative;
  font-size: 0;
  @include xs {
    padding-top: 20px;
    padding-bottom: 20px; }
  &__title {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    .title {
      display: inline-block; }
    &.has-no-mb {
      margin-bottom: 0; }
    &.has-mb-sm {
      margin-bottom: 15px; }
    &.has-mb-lg {
      margin-bottom: 5%; }
    &.is-left {
      text-align: left; }
    &.has-line {
      position: relative;
      &::before {
        content: '';
        height: 1px;
        width: 53px;
        background-color: $c-text;
        position: absolute;
        bottom: -15px;
        left: 0; } }
    &.has-quote {
      display: flex;
      align-items: center;
      .icon {
        fill: $c-accent;
        font-size: 100px;
        margin-right: 15px; } } }
  &__subttl {
    max-width: 470px;
    text-align: center;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; }
    &.is-left {
      text-align: left;
      margin-left: 0;
      margin-right: 0;
      max-width: none; }
    &.has-mb-md {
      margin-bottom: 20px;
      @include md {
        margin-bottom: 15px; } } }
  &__links-list {
    display: flex;
    margin-bottom: 35px;
    @include md {
      margin-bottom: 20px; }
    .links-list {
      &:not(:last-child) {
        margin-right: 50px; } } }
  &__block {
    text-align: justify;
    &:not(:last-child) {
      margin-bottom: 50px;
      @include md {
        margin-bottom: 30px; } }
    p {
      font-size: 15px;
      margin-bottom: 35px;
      @include light;
      @include md {
        margin-bottom: 20px; }
      strong {
        @include bold; }
      a {
        color: #025db9;
        text-decoration: underline; }
      &.has-no-mb {
        margin-bottom: 0; } } }
  &__img {
    @include sm {
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      display: block; } }
  &__img-offset-left {
    max-width: 110%;
    margin-left: -10%;
    display: block;
    @include lg {
      max-width: 100%;
      margin-left: 0; }
    @include md {
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      margin-bottom: 15px; } }

  &--bg {
    @include cover;
    @include md {
      background-image: none !important; }
    .container {
      position: relative;
      z-index: 1; } }
  &--bg-right {
    @include cover;
    background-position: 100% 50%; }
  &--pt-sm {
    padding-top: 1%; }
  &--bd-top {
    @include xs {
      border-top: 1px solid #e6e6e6; } }
  &--bd-top-xl {
    border-top: 1px solid #e6e6e6; }
  &--md {
    padding-top: 40px;
    padding-bottom: 0;
    @include sm {
      padding-top: 20px;
      padding-bottom: 0; } }
  &--lg {
    padding-top: 5%;
    padding-bottom: 0; }
  &--pad {
    padding-top: 40px;
    padding-bottom: 40px; }
  &--gray {
    background-color: #f7f7f7; }
  &--full {
    height: calc(100vh - 90px);
    @include lg {
      height: calc(100vh - 45px); } }
  &--xl {
    padding-top: 9%;
    padding-bottom: 9%; }
  &--inherit {
    font-size: inherit; } }

.car-bg {
  @include cover;
  background-size: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 36.5%;
  z-index: 0; }

.section-full {
  position: relative;
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  overflow: hidden;
  @include cover;
  @include lg {
    height: calc(100vh - 45px); }
  &.has-bottom-block {
    align-items: flex-end; }
  &__block {
    z-index: 1;
    background-color: #fff;
    max-width: 565px;
    padding: 35px;
    @include sm {
      padding: 25px;
      padding-top: 20px; }
    @include xs {
      padding: 15px;
      padding-top: 10px; }
    .model-descr__text {
      p {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0; } } }
    &.is-bottom-right {
      position: relative;
      // bottom: -1px
      left: 50%;
      @include sm {
        position: static;
        width: auto;
        max-width: none; } }
    &.is-bottom-left {
      position: absolute;
      bottom: -1px;
      right: 50%;
      @include lg {
        position: relative;
        right: auto;
        bottom: auto; }
      @include sm {
        max-width: none; } } }
  &__bg {
    @include coverdiv;
    z-index: 0;
    video {
      min-width: 100%;
      min-height: 100%; }
    &::before {
      content: '';
      @include coverdiv;
      background-color: rgba(#000, .5); } }
  &__left,
  &__right {
    @include cover;
    padding-top: 45%;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    @include lg {
      position: absolute;
      top: 0;
      height: 100%; }
    @include sm {
      height: auto;
      padding-top: 50%; } }
  &__left {
    @include lg {
      left: 0; } }
  &__right {
    @include lg {
      right: 0; } }

  &--lg {
    height: 110vh; }
  &--sm {
    height: auto;
    display: block;
    font-size: 0;
    @include lg {
      height: auto;
      padding-top: 40%; }
    @include sm {
      padding-top: 50%; } } }

.section-comb {
  position: relative;
  &__left {
    height: calc(100vh - 90px);
    padding-top: 85px;
    @include lg {
      height: calc(100vh - 45px); }
    @include md {
      height: auto;
      padding-top: 0px; } }
  &__right {
    position: absolute;
    @include cover;
    z-index: 2;
    top: 0;
    right: 0;
    height: 100%;
    // width: percentage(745/1920)
    width: 50%;
    background-color: #000;
    @include lg {
      width: 50%; }
    @include md {
      position: static;
      width: auto;
      height: auto;
      padding-top: 70%; } } }

.section-spinner {
  height: calc(100vh - 90px);
  padding-bottom: 5%;
  position: relative;
  @include vertical;
  @include cover;
  @include lg {
    height: calc(100vh - 45px); }
  @include sm {
    height: auto;
    padding-top: 30%;
    padding-bottom: 12%; }
  @include xs {
    padding-top: 20%;
    padding-bottom: 8%; }
  &__icon-wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: left; }
  &__spinner {
    display: inline-block;
    vertical-align: bottom;
    padding-top: 23%;
    width: 50%;
    position: relative;
    @include md {
      width: 60%;
      padding-top: 30%; }
    @include sm {
      width: 100%;
      padding-top: 40%; }

    .spritespin-instance {
      @include coverdiv;
      height: 100% !important;
      width: 100% !important;
      position: absolute !important;
      cursor: grab; } }
  &__icon {
    background-color: rgba($c-text, .8);
    text-align: center;
    padding: 20px 30px;
    display: inline-block;
    .icon {
      fill: #fff;
      font-size: 38px; }
    @include xs {
      .icon {
        font-size: 20px; } } } }

.section-comb-block {
  position: relative;
  margin-bottom: 80px;
  @include md {
    margin-bottom: 40px; }
  @include sm {
    margin-bottom: 20px; }
  &__img {
    @include cover;
    // height: 100%
    position: absolute;
    width: 40%;
    top: 0;
    bottom: 0;
    left: 0;
    background-position: 100% 50%;
    @include md {
      position: static;
      padding-top: 70%;
      width: auto;
 }      // display: none
    &.is-right {
      right: 0;
      left: auto;
      background-position: 0 50%;
      width: 35%;
      @include md {
        width: auto; } } }
  &__block {
    .h2, h2 {
      margin-bottom: 14px; }
    .complectation-list {
      margin-bottom: 25px; } } }

.s-specialists {
  @include cover;
  color: #fff;
  &__title {
    &::before {
      background-color: #fff; } }
  &__subttl {
    color: #b9edca; } }

.s-service-assort {
  background-position: 100% 50%;
  border-top: 1px solid  $c-border; }
