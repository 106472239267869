.full-grid {
  overflow: hidden;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-right: -2px;
    margin-bottom: -4px;
    @include lg {
      margin-left: 0;
      margin-right: 0; } }
  &__block {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 4px;
    @include cover;
    background-color: $c-text;
    padding-top: 30%;
    position: relative;
    width: calc(50% - 4px);
    @include xl {
      padding-top: 40%; }
    @include lg {
      width: 100%;
      margin-left: 0;
      margin-right: 0; }
    @include md {
      padding-top: 30%; }
    @include sm {
      padding-top: 40%; }
    @include xs {
      padding-top: 300px; }
    &--full {
      width: 100%; }
    &--high {
      padding-top: 60%;
      @include xl {
        padding-top: 70%; }
      @include md {
        padding-top: 60%; }
      @include sm {
        padding-top: 70%; }
      @include xs {
        padding-top: 80%; } } } }


.full-grid-block {
  $this: &;
  position: relative;
  &__video {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  &__content {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    padding: 30px;
    max-width: 575px;
    @include md {
      padding: 15px;
      max-width: none;
      width: 100%;
      position: static; } }
  &__title {
    margin-bottom: 15px; }
  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: 15px; } } }

  &--full {
    #{$this}__content {
      right: auto;
      left: 50%; } } }


