.item-list {
  &__title {
    font-weight: 400;
    @include bold;
    font-size: 15px;
    margin-right: 10px;
    text-align: left;
    width: percentage(91/315); }
  &__text {
    @include light;
    width: percentage(224/315);
    font-size: 15px;
    &.is-green {
      color: $c-accent; } } }
