.models-info {
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    @include sm {
      .icon {
        font-size: 22px; }
      // .icon-speedometer
 }      //   font-size: 32px
    @include xs {
      display: inline-block;
      position: static;
      vertical-align: middle; }
    .icon {
      font-size: 28px;
      fill: $c-text; }
    .icon-speedometer {
      // font-size: 30px
 } }      // transform: translate(-13px, 0px)
  &__item {
    text-align: left;
    height: 100%;
    position: relative;
    @include md {
      margin-bottom: 10px; }
    @include sm {
      text-align: center; }
    @include xs {
      &:not(.has-btn) {
        display: none; } }
    &::before {
      content: '';
      position: absolute;
      height: 48px;
      top: 5px;
      right: 0;
      width: 1px;
      background-color: $c-text;
      @include md {
        display: none; } }
    &.has-no-line {
      &::before {
        display: none; } }
    &.has-icon {
      padding-left: 30px;
      @include sm {
        padding-left: 20px; }
      @include xs {
        padding-left: 0; } } }
  &__title {
    @include bold;
    font-size: 32px;
    line-height: 1.15;
    @include sm {
      // display: inline-block
      // vertical-align: middle
      font-size: 24px; }
    @include xs {
      display: inline-block;
      vertical-align: middle; }
    span {
      font-size: 13px; } }
  &__subttl {
    font-size: 15px;
    color: #9b9b9b; }
  &__link {
    position: absolute;
    top: -15px;
    transform: translate(0, -100%);
    @include lg {
      position: static;
      transform: translate(0, 0);
      margin-bottom: 10px; }
    @include sm {
      .link {
        justify-content: center; } } } }
