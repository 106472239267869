.specialist {
  text-align: center;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  &__img {
    @include cover;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    &::before, &::after {
      content: '';
      @include vcenter; }
    &::before {
      width: 100%;
      height: 100%;
      border: 10px solid #28944d;
      border-radius: 50%; }
    &::after {
      border: 10px solid #2cb459;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      border-radius: 50%; } }
  &__top {
    margin-bottom: 7px;
    border-bottom: 1px solid #3ada70;
    padding-bottom: 7px; }
  &__exper {
    color: #b9edca; } }

