$icons: (0:0);
$icons: map-merge($icons,('map-green': (X: 0px, Y:0px, W: 32px, H: 46px, TW: 73px, TH: 46px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('map-orange': (X: -42px, Y:0px, W: 31px, H: 44px, TW: 73px, TH: 46px, IMG: '../img/sprite.png')));


// Gets an attribute from the sass map
@function icon-attr($icon, $attr) {
  $icon: map-get($icons, $icon);
  @return map-get($icon, $attr); }

@mixin sprite($iconName) {
  background-image: url(icon-attr($iconName, IMG));
  width: icon-attr($iconName, W);
  height: icon-attr($iconName, H);
  background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-position($iconName) {
  background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-retina($iconName) {
  background-image: url(icon-attr($iconName, IMG));
  $width: icon-attr($iconName, W);
  $height: icon-attr($iconName, H);
  width: $width/2;
  height: $height/2;
  $x: icon-attr($iconName, X);
  $y: icon-attr($iconName, Y);
  background-position: $x/2 $y/2;
  $tw: icon-attr($iconName, TW);
  $th: icon-attr($iconName, TH);
  background-size: $tw/2 $th/2; }


@mixin s($i) {
  @include sprite($i); }
@mixin sp($i) {
  @include sprite-position($i); }
@mixin sr($i) {
  @include sprite-retina($i); }

//
//.icon-map-green
//  width: 32px
//  height: 46px
//  background-image: url('../img/sprite.png')
//  background-position: 0px 0px
//
//.icon-map-orange
//  width: 31px
//  height: 44px
//  background-image: url('../img/sprite.png')
//  background-position: -42px 0px
//
