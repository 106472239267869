@include font('helveticaneuecyr-bold', 'helveticaneuecyr-bold-webfont');
@include font('helveticaneuecyr-roman', 'helveticaneuecyr-roman-webfont');
@include font('helveticaneuecyr-light', 'helveticaneuecyr-light-webfont');
@include font('helveticaneuecyr-medium', 'helveticaneuecyr-medium-webfont');
@include font('helveticaneuecyr-thin', 'helveticaneuecyr-thin-webfont');

@mixin roman {
  font-family: 'helveticaneuecyr-roman', Arial, sans-serif; }
@mixin bold {
  font-family: 'helveticaneuecyr-bold', Arial, sans-serif; }
@mixin medium {
  font-family: 'helveticaneuecyr-medium', Arial, sans-serif; }
@mixin light {
  font-family: 'helveticaneuecyr-light', Arial, sans-serif; }
@mixin thin {
  font-family: 'helveticaneuecyr-thin', Arial, sans-serif; }

@mixin xl {
  @include r(1440) {
    @content; } }
@mixin lg {
  @include r(1199) {
    @content; } }
@mixin md {
  @include r(991) {
    @content; } }
@mixin sm {
  @include r(767) {
    @content; } }
@mixin xs {
  @include r(575) {
    @content; } }
@mixin xss {
  @include r(479) {
    @content; } }

@mixin mdmin {
  @include rmin(992) {
    @content; } }

img, iframe {
  max-width: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

strong {
  font-weight: 400; }

html {
  // overflow: visible
  // position: static
  @include no-scroll; }

body {
  background: #fff;
  line-height: 1.4;
  font-size: 15px;
  overflow: visible;
  position: static;
  overflow-x: hidden;
  @include roman;
  color: $c-text;
  @include no-scroll; }

// .js-lazy
//   &[data-loaded="true"]
//     animation: fadeIn 3s

// @keyframes fadeIn
//   from
//     opacity: 0

//   to
//     opacity: 1

.ic {
  display: inline-block; }

.ic-map-green {
  @include sprite(map-green); }

.ic-map-orange {
  @include sprite(map-orange); }

.margin-bottom-5 {
  margin-bottom: 5px; }


.out {
  padding-top: 90px;
  // overflow-x: hidden
  @include lg {
    padding-top: 45px; }
  @include xs {
    padding-bottom: 50px; } }

.js-sticky-column {
  position: sticky;
  top: 90px;
  @include lg {
    top: 45px; } }

.btn {
  color: #fff;
  text-transform: uppercase;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  @include light;
  text-align: center;
  font-size: 0;
  height: 50px;
  @include hover {
    .btn__title {
      background-color: #282822; } }
  &.is-active {
    .btn {
      &__icon {
        &.is-arrow {
          &::before {
            transform: translate(0, 0) rotate(135deg);
            top: 20px; } } } } }

  &__title {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    background-color: $c-accent;
    padding: 17px 10px;
    text-align: center;
    min-width: 180px;
    transition: color .3s, background-color .3s;
    .icon {
      display: inline-block;
      vertical-align: sub;
      font-size: 16px;
      margin-left: 5px;
      transition: fill .3s; } }
  &__icon {
    display: inline-block;
    background-color: #2c2c2c;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #2c2c2c;
    width: 50px;
    .icon {
      font-size: 16px;
      fill: #fff; }
    .icon-hatchback {
      font-size: 30px;
      position: relative;
      top: 9px; }
    .icon-repair-service {
      font-size: 26px; }
    &.is-arrow {
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        @include icon-arrow(12, 12, 2, #fff, 225);
        position: absolute;
        top: 50%;
        transform: translate(-3px, -50%) rotate(225deg);
        transition: transform .3s; } }
    &.is-arrow-down {
      &::before {
        transform: translate(0, 0) rotate(-45deg);
        left: 18px;
        top: 14px; } } }
  &--lg {
    .btn__title {
      min-width: 220px; } }
  &--sm {
    .btn__title {
      min-width: 145px; } }
  &--xs {
    height: 35px;
    .btn__title {

      padding-top: 8px;
      padding-bottom: 8px; } }

  &--gray {
    .btn__title {
      background-color: #f7f7f7;
      color: $c-text; }
    @include hover {
      .btn__title {
        background-color: $c-accent;
        color: #fff;
        .icon {
          fill: #fff; } } } }
  &--white {
    .btn__title {
      background-color: #fff;
      color: $c-text; }
    @include hover {
      .btn__title {
        background-color: $c-accent;
        color: #fff;
        .icon {
          fill: #fff; } } } }
  &--ttn {
    text-transform: none;
    height: 45px;
    .btn__title {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 15px;
      line-height: 1.1; } }
  &--full {
    width: 100%;
    max-width: none;
    overflow: hidden;
    .btn__title {
      width: 100%; } }
  &--full-both {
    height: 100%;
    width: 100%;
    max-width: none;
    .btn__title {
      width: 100%;
      padding: 23px 10px; } }
  &--full-width {
    width: 100%;
    @include xs {
      height: auto; }
    .btn__title {
      max-width: none;
      width: calc(100% - 50px);
      @include xs {
        width: 100%; } }
    .btn__icon {
       @include xs {
          display: none; } } }
  &--full-height {
    height: 100%; }
  &--icon-full {
    width: 100%;
    .btn__title {
      width: calc(100% - 50px); } }
  &--hauto {
    height: auto;
    .btn__title {
      padding-top: 13px;
      padding-bottom: 13px; }
    .btn__icon {
      height: 45px; } } }

.btn-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $c-accent;
  color: $c-accent;
  position: relative;
  height: 45px;
  padding: 10px;
  transition: color .3s, background-color .3s, border-color .3s;
  @include hover {
    color: #fff;
    background-color: $c-accent; }

  &__title {
    display: block;
    margin-right: 7px; }
  &__arrow {
    display: block;
    width: 30px;
    position: relative;
    height: 1px;
    &::before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: currentColor;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%); }
    &::after {
      content: '';
      @include icon-arrow(10, 10, 1, currentColor, -135);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%) rotate(-135deg); } } }


.link {
  font-size: 15px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  // padding-right: 100px
  position: relative;
  transition: color .3s;
  @include hover {
    .link__arrow {
      width: 100px; } }
  &__title {
    text-decoration: underline;
    transition: color .3s;
    margin-right: 25px; }
  &__arrow {
    position: relative;
    width: 85px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    transition: transform .3s, width .3s;
    &::before {
      width: 100%;
      content: '';
      position: absolute;
      height: 1px;
      background-color: #fff;
      top: 50%;
      right: -1px;
      transform: translate(0, -50%);
      transition: width .3s; }
    &::after {
      display: inline-block;
      position: absolute;
      @include icon-arrow(8, 8, 1, #fff, 225);
      content: '';
      top: 50%;
      transform: translate(0, -50%) rotate(225deg);
      right: 0; } }
  &--green {
    color: $c-accent;
    .link__arrow {
      &::before {
        background-color: $c-text; }
      &::after {
        border-color: $c-text; } }
    &::before {
      background-color: $c-text; }
    &::after {
      @include icon-arrow(8, 8, 1, $c-text, 225); } }
  &--sm {
    // padding-right: 45px
    @include hover {
      .link__arrow {
        width: 50px; } }
    .link__title {
      margin-right: 12px; }
    .link__arrow {
      width: 30px;
      &::before {
        width: 100%;
        background-color: $c-text; }
      &::after {
        border-color: $c-text; } } }
  &--simple {
    color: $c-text;
    @include hover {
      .link__title {
        color: $c-accent; } }
    .link__title {
      text-decoration: none; }
    .link__arrow {
      &::before {
        background-color: $c-text; }
      &::after {
        border-color: $c-text; } } } }

.phone {
  display: inline-flex;
  align-items: center;
  &__phone {
    @include bold;
    font-size: 32px;
    color: #202020;
    line-height: 1.15;
    a {
      transition: color .3s; }
    @include hover {
      a {
        color: $c-accent; } } }
  &__icon {
    width: 44px;
    height: 38px;
    background-color: $c-accent;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      fill: #fff;
      font-size: 16px;
      stroke: #fff; } } }

.map-link {
  display: flex;
  align-items: center;
  &__icon {
    display: block;
    font-size: 0;
    margin-right: 10px;
    margin-top: 2px;
    @include xs {
      display: none; }
    .icon {
      font-size: 18px;
      fill: #b6b6b6; } }
  &__title {
    color: #b6b6b6;
    font-size: 14px;
    display: block;
    @include light;
    br {
      display: none;
      @include xs {
        display: block; } } } }

.side-btns {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  z-index: 10;
  @include xs {
    bottom: 0;
    top: auto;
    transform: translate(0, 0);
    width: 100%;
    display: flex; } }

.side-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $c-accent;
  width: 50px;
  height: 45px;
  position: relative;
  overflow: hidden;
  transition: background-color .3s;
  @include hover {
    overflow: visible;
    .side-btn__title {
      transform: translate(-100%, 0); } }
  @include xs {
    width: 50%;
    height: 50px;
    @include hover {
        background-color: #282822; }
    &:not(:last-child) {
      margin-bottom: 0; }
    &:first-child {
      background-color: #f7f7f7;
      @include hover {
        .icon {
          fill: #fff; } }
      .icon {
        fill: $c-text; } } }

  .icon {
    fill: #fff;
    font-size: 23px;
    transition: fill .3s; }
  &:not(:last-child) {
    margin-bottom: 10px; }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(100%, 0);
    background-color: #2c2c2c;
    color: #fff;
    @include light;
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
    min-width: 180px;
    transition: transform .3s;
    z-index: -1;
    @include xs {
      display: none; } } }

.items-page {
  padding-bottom: 30px; }

.price {
  &__title {
    font-size: 30px;
    @include bold;
    color: $c-accent; }
  &__subttl {
    color: #a7a7a7;
    font-size: 13px; }
  &--sm {
    font-size: 14px;
    color: $c-accent;
    strong {
      font-size: 24px;
      @include bold; } } }

.price-sm {
  color: $c-accent;
  @include bold; }

.s-contacts {
  &__top {
    background-color: #f8f8f8; } }

.show-more {
  font-size: 24px;
  color: $c-accent;
  display: inline-flex;
  align-items: center;
  .icon {
    fill: currentColor;
    margin-left: 10px; } }

.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  &__title {
    @include css-lock(23, 32, 574, 1199, font-size); }
  &__subttl {
    @include xs {
      font-size: 13px; } }
  &__item {
    position: relative;
    flex-grow: 1;
    padding-left: 60px;
    padding-right: 15px;
    border-right: 1px solid  $c-text;
    @include md {
      padding-left: 15px; }
    @include sm {
      width: 50%;
      margin-bottom: 15px;
      padding-left: 0;
      &:nth-child(2n-2) {
        padding-left: 15px;
        border-right: 0; } }
    &:first-child {
      padding-left: 0; }
    &:last-child {
      border-right: 0; } } }

.map {
  padding-top: 40%;
  @include xs {
    padding-top: 60%; } }

.error-page {
  &__title-lg {
    @include medium;
    font-size: 80px;
    text-transform: capitalize; }
  &__title {
    margin-bottom: 30px; }
  &__content {
    height: 100%; }
  &__list {
    margin-bottom: 15px; }
  &__btn {
    &:not(:last-child) {
      margin-bottom: 20px; } }
  &__img {
    padding-top: 20%;
    @include sm {
      padding-bottom: 65%; }
    img {
      position: absolute;
      top: 55%;
      left: 12%;
      z-index: 1;
      max-width: 100%; } }
  &__img-in {
    position: relative; }
  &__404 {
    font-size: 248px;
    line-height: 0.7;
    @include medium;
    position: relative;
    &::before {
      content: '404';
      position: absolute;
      top: 100%;
      transform: scaleY(-1);
      left: 0;
      opacity: 0.1; } } }


.error-page-list {
  &__title {
    margin-bottom: 15px; }
  &__link {
    font-size: 15px;
    text-decoration: underline;
    color: #025db9; }
  &__item {
    &:not(:last-child) {
      margin-bottom: 8px; } } }

.text-block {
  &__title {
    text-align: left;
    margin-bottom: 15px; }
  &__text {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } } }

