.hero-btns {
  &__list {
    font-size: 0; }
  &__item {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    @include md {
      width: 50%; }
    @include xs {
      width: 100%; }

    &:not(:last-child) {
      .hero-btns__link {
        border-right: 1px solid  #191919; } } }
  &__link {
    font-size: 15px;
    color: #828282;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fbfbfb;
    padding: 10px;
    border-bottom: 2px solid transparent;
    transition: background-color .3s, border-color .3s, color .3s;
    @include md {
      border-bottom: 1px solid  #191919;
      border-right: 1px solid  #191919; }
    .icon {
      fill: currentColor;
      font-size: 15px;
      margin-left: 10px; }
    @include hover {
      @extend .hero-btns__link.is-active; }
    &.is-active {
      color: #fff;
      background-color: #191919;
      border-color: transparent;
      border-bottom: 2px solid $c-accent; } } }

