.models-info-list {
  &__top {
    background-color: #f4f4f4;
    padding-top: 5px;
    padding-bottom: 5px;
    @include xs {
      padding-top: 10px;
      padding-bottom: 10px; }
    &.is-fixed {
      position: fixed;
      top: 90px;
      z-index: 10;
      left: 0;
      width: 100%;
      @include lg {
        top: 45px; } } }
  &__top-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include md {
      // display: block
      justify-content: space-between; } }

  &__logo {
    display: flex;
    align-items: center;
    border-right: 1px solid $c-border;
    padding-right: 30px;
    .title {
      font-size: 26px; }
    @include md {
      display: inline-flex;
      align-items: center;
      vertical-align: top;
      margin-bottom: 0;
      .h2, h2 {
        // display: inline-block
 } }        // vertical-align: middle
    @include sm {
      border-right: 0;
      display: flex; }
    @include xs {
      margin-bottom: 0; }


    img {
      margin-right: 25px;
      max-height: 35px; } }
  &__title {
    padding-left: 30px;
    flex-grow: 1;
    .h2 {
      font-size: 24px; }
    @include md {
      // display: inline-block
      // vertical-align: top
      display: none;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-left: 0; }
    @include md {
 }      // display: none
    // +sm
    //   padding-left: 0
    // +xs
 }    //   display: none
  &__link {
    @include sm {
      display: none; } } }

.car-info-item {
  padding-top: 30px;
  padding-bottom: 40px;
  &:not(:last-child) {
    border-bottom: 1px solid  $c-border; }
  &__title {
    margin-bottom: 10px;
    .h2, h2 {
      margin-bottom: 5px; }
    .item-label {
      display: inline-flex; } }
  &__tech-info {
    margin-bottom: -15px;
    @include sm {
      margin-bottom: 15px; } }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include xs {
      display: block;
      text-align: center;
      .btn {
        margin-bottom: 15px; } } }
  &__img {
    @include md {
      margin-bottom: 15px;
      text-align: center; }
    img {
      max-width: 100%; } }
  &__text {
    margin-bottom: 12px; } }
