.consultation {
  $this: &;
  position: relative;
  &__left {
    .phone {
      @include lg {
        margin-bottom: 10px; } } }
  &__right {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    height: 100%;
    // width: percentage(745/1920)
    width: 36.5%;
    background-color: #000;
    @include lg {
      width: 50%; }
    @include sm {
      position: static;
      width: auto;
      height: auto; }
    .slick-slide {
      > div {
        height: 100%; } }
    .consultation-slider, .slider--consultation__wrap, .slider, .slick-list, .slick-track, .slide {
      height: 100%;
      font-size: 0; } }
  &__top {
    background-color: #f7f7f7;
    padding-top: 75px;
    padding-bottom: 75px;
    border-bottom: 1px solid  #e6e6e6;
    @include md {
      padding-top: 40px;
      padding-bottom: 40px; } }
  &__bottom {
    padding-top: 75px;
    padding-bottom: 85px;
    background-color: #fff;
    @include md {
      padding-top: 40px;
      padding-bottom: 40px;
      display: none; } }
  &__title {
    margin-bottom: 20px; }

  &--reverse {
    #{$this}__top {
      background-color: #fff; }
    #{$this}__bottom {
      background-color: #f7f7f7; } } }
