.model-gallery {
  padding-top: 40px;
  padding-bottom: 40px;
  @include sm {
    padding-bottom: 20px;
    padding-top: 20px; }
  @include xs {
    padding-top: 10px;
    padding-bottom: 10px; }
  .container {
    position: relative; }
  &__preview-wrap {
    position: relative;
    height: 100%;
    @include sm {
      height: auto; } }
  &__preview {
    height: 100%;
    @include sm {
      height: auto;
      padding-top: 60%; } }
  &__preview-next,
  &__preview-prev {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 16px;
    display: inline-block;
    background-color: rgba(#000, .5);
    width: 40px;
    height: 40px; }
  &__preview-next {
    right: 10px;
    &:before {
      content: '';
      display: inline-block;

      @include vcenter;
      @include icon-arrow(18, 18, 1, #fff, -135);
      transform: translate(0, -50%) rotate(-135deg);
      right: 16px;
      left: auto; } }
  &__preview-prev {
    left: 10px;
    &:before {
      content: '';
      display: inline-block;

      @include vcenter;
      @include icon-arrow(18, 18, 1, #fff, 45);
      transform: translate(0, -50%) rotate(45deg);
      left: 16px; } }
  &__img {
    @include cover;
    // background-size: contain
    // padding-top: 50%
    // padding-top: 100%
    font-size: 0;
    @include coverdiv;
    visibility: hidden;
    opacity: 0;
    display: block;
    transition: opacity .5s, visibility .5s;
    img {
      width: 100%;
      max-width: 100%; }
    @include sm {
      // padding-top: 50%
 }      // position: static
    &.is-active {
      visibility: visible;
      opacity: 1; } }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 20px
    // border-bottom: 1px solid  $c-border
    @include lg {
      display: block;
      .model-gallery__price {
        margin-bottom: 10px; } }
    @include md {
      display: flex;
      .model-gallery__price {
        margin-bottom: 0; } }
    @include xs {
      display: block;
      padding-bottom: 15px;
      .model-gallery__price {
        margin-bottom: 10px; } } }
  &__more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid  $c-border;
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 10px;
    @include lg {
      flex-direction: column; }
    @include md {
      flex-direction: row; }
    @include xs {
      flex-direction: column;
      align-items: flex-start;
      .text {
        margin-bottom: 10px; } } }
  &__more-btn {
 }    // font-size: 0
  &__more-price {
    @include xs {
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      .text {
        padding-left: 10px;
        text-align: left;
        position: relative;
        top: 6px; } }
    @include r(360) {
      display: block;
      .text {
        top: auto;
        padding-left: 0; } } }
  &__title-mob {
    display: none;
    @include xs {
      display: block; } }
  &__img-label {
    position: absolute;
    top: 10px;
    right: 10px;
    pointer-events: none; }
  &__img-info {
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    @include roman;
    padding: 13px 20px;
    height: 48px;
    background-color: rgba(#000, .45);
    font-size: 15px;
    position: absolute;
    bottom: 10px;
    white-space: nowrap;
    transition: background-color .3s;
    @include hover {
      background-color: rgba($c-accent, .9); }
    sup {
      vertical-align: super; }
    .icon {
      fill: currentColor;
      font-size: 16px;
      margin-right: 10px; }
    .icon-reload {
      font-size: 20px; }
    .icon-play-button {
      font-size: 14px; } }
  &__img-counter {
    left: 10px;
    @include hover {
      background-color: rgba(#000, .45); } }
  &__img-angle {
    right: 135px; }
  &__img-video {
    right: 10px; }
  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #828282;
    font-size: 15px;
    @include light;
    @include hover {
      img {
        display: block;
        @include xl {
          display: none; } } }
    @include xl {
      font-size: 0; }
    @include lg {
      display: none; }
    img {
      position: absolute;
      width: auto;
      height: auto;
      top: 50%;
      display: none;
      @include r(1850) {
        top: auto;
        transform: translate(0, 0);
        margin-bottom: 15px;
        bottom: 100%; } }
    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 3px; } }
  &__prev {
    left: -25px;
    padding-left: 20px;
    transform: translate(-100%, 0);
    @include xl {
      left: 0; }
    @include md {
      left: 7px; }
    img {
      left: -25px;
      transform: translate(-100%, -50%);
      @include r(1850) {
        left: auto;
        right: 0; } }

    &::before {
      left: 0;
      @include icon-arrow(15, 15, 1, currentColor, 45); } }
  &__next {
    right: -25px;
    padding-right: 20px;
    transform: translate(100%, 0);
    @include xl {
      right: 0; }
    @include md {
      right: 7px; }
    img {
      right: -25px;
      transform: translate(100%, -50%);
      @include r(1850) {
        right: auto;
        left: 0; } }
    &::before {
      right: 0;
      @include icon-arrow(15, 15, 1, currentColor, -135); } }
  &__meta {
    .model-gallery-meta {
      border-top: 1px solid  $c-border; }
    @include md {
      padding-top: 30px; }
    @include xs {
      padding-top: 0px; } } }


.model-gallery-meta {
  padding-top: 15px;
  &__list {
    @include clr; }
  &__title,
  &__text {
    @include xs {
      margin-bottom: 5px;
      padding-bottom: 5px; } }
  &__title {
    @include bold;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    float: left;
    width: 45%;
    margin-bottom: 10px;
    @include md {
      width: 16.6666666667%;
 }      // width: auto
    @include sm {
      width: 25%; }
    @include xs {
      width: 30%; }
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 30px;
      background-color: $c-border;
      left: 0;
      bottom: 0; }
    &.has-no-bdbotttom {
      &::before {
        display: none; } } }
  &__text {
    @include light;
    font-size: 15px;
    padding-bottom: 10px;
    width: 55%;
    float: left;
    margin-bottom: 10px;
    @include md {
      // width: 85%
      width: 16.6666666667%;
      // width: auto
      float: left; }
    @include sm {
      width: 25%; }
    @include xs {
      width: 70%; } } }
