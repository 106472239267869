.items {
  &__top {
    margin-bottom: 30px; }
  &__item {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.33333%;
    @include lg {
      width: 50%; }
    @include sm {
      width: 100%; }
    &.is-full {
      width: 100%; } }
  &__pagination {
    text-align: center; } }


.items-top {
  border: 1px solid  #e7e7e7;
  padding: 10px 20px 10px 15px;
  // display: flex
  // justify-content: flex-start
  // align-items: center
  @include lg {
    display: block;
    font-size: 0; }
  &__btns {
    font-size: 0;
    margin-right: 30px;
    display: inline-block;
    vertical-align: middle;
    @include lg {
      display: inline-block;
      vertical-align: middle;
 } }      // margin-right: 0
  &__btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    &:not(:last-child) {
      margin-right: 15px; }
    .icon {
      font-size: 16px;
      fill: #aeaeae;
      transition: fill .3s; }
    .icon-list {
      font-size: 25px; }
    &.is-active {
      .icon {
        fill: $c-accent; } }
    @include hover {
      @extend .items-top__btn.is-active; } }
  &__title {
    color: #aeaeae;
    @include light;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
    @include lg {
      display: inline-block;
      vertical-align: middle; } }
  &__selects {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    @include lg {
      justify-content: flex-start; }
    @include md {
      padding-top: 10px;
      display: block; } }
  &__select {
    @include md {
      width: 100%; }
    &:last-child {
      margin-left: 30px;
      @include md {
        margin-left: 0; } }
    &:not(:last-child) {
      @include md {
        margin-bottom: 10px; } } } }

.item {
  transition: box-shadow .2s;
  border: 1px solid  $c-border;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  padding-bottom: 45px;
  position: relative;
  @include hover {
    box-shadow: 0px 0px 12.92px 4.08px rgba(0, 0, 1, 0.15); }
  &:not(.item--row) {
    .row {
      display: block; }
    .col-md-5, .col-md-7 {
      width: 100%;
      max-width: none;
      flex: 0 0 100%;
      position: static; } }
  &__content {
    padding: 12px 15px 5px; }
  &__img {
    @include cover;
    padding-top: 70%;
    background-color: #ccc;
    @include sm {
      padding-top: 50%; }
    @include xs {
      padding-top: 40%; } }
  &__img-bg {
    display: block;
    @include cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 70%;
    @include sm {
      padding-top: 50%; }
    @include xs {
      padding-top: 40%; }
    .icon {
      position: absolute;
      top: 10px;
      right: 10px;
      fill: #fff;
      font-size: 24px; } }
  &__img-bg-in {
    @include cover;
    @include coverdiv; }
  &__content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    @include lg {
      display: inline-flex;
      .item-label {
        margin-right: 20px; } }
    @include sm {
      margin-top: -45px;
      margin-bottom: 15px; } }
  &__video-link {
    font-size: 13px;
    color: #8d8d8d;
    transition: color .3s;
    @include sm {
      color: #fff;
      background-color: $c-accent;
      padding: 3px 5px;
      border-radius: 3px; }
    .icon {
      fill: currentColor;
      font-size: 14px;
      position: relative;
      top: 3px; }
    @include hover {
      text-decoration: underline;
      color: $c-accent; } }
  &__title {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    @include sm {
      padding-bottom: 3px;
      margin-bottom: 4px;
      .h4, h4 {
        font-size: 15px;
        line-height: 1.2; } }
    .h4, h4 {
 }      // work-break: wrap
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: #c1c1c1;
      width: 30px; } }
  &__info {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #f9f9f9;
    @include sm {
      .item-info-item {
        margin-bottom: 3px; } }
    .item-info-item {
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        display: none; } } }
  &__price-lg {
    font-size: 21px;
    @include bold;
    color: $c-accent; }
  &__price-sm {
    @include light;
    color: #a7a7a7;
    font-size: 13px; }
  &__price {
    padding-top: 10px;
    padding-bottom: 10px;
    @include sm {
      padding-top: 5px;
      padding-bottom: 5px; } }
  &__text {
    display: none;
    font-size: 15px;
    color: #828282;
    text-align: justify;
    margin-bottom: 15px; }
  &__btn {
    background-color: #2c2c2c;
    font-size: 15px;
    @include light;
    color: #fff;
    width: 100%;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    transition: background-color .3s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include hover {
      background-color: $c-accent;
      .item__btn-arrow {
        width: 70px; } } }
  &__btn-title {
    margin-right: 10px; }
  &__btn-arrow {
    position: relative;
    width: 28px;
    display: block;
    transition: width .3s;
    &::before {
      width: 100%;
      content: '';
      position: absolute;
      height: 1px;
      background-color: #fff;
      top: 50%;
      right: -1px;
      transform: translate(0, -50%);
      transition: width .3s; }
    &::after {
      display: inline-block;
      position: absolute;
      @include icon-arrow(8, 8, 1, #fff, 225);
      content: '';
      top: 50%;
      transform: translate(0, -50%) rotate(225deg);
      right: 0; } }

  &--row {
    position: relative;
    padding-bottom: 0;
    .row {
      display: flex; }
    @include sm {
      padding-bottom: 45px; }
    .item-info-item {
      width: percentage(4/12);
      @include xs {
        width: 50%; } }
    .col-md-5 {
      position: static; }
    .item {
      &__img {
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        @include sm {
          padding-top: 40%;
          position: static; }
        @include xs {
 } }          // padding-top: 50%
      &__img-bg {
        height: 100%;
        padding-top: 0;
        @include sm {
          padding-top: 40%; }
        @include xs {
 } }          // padding-top: 50%
      &__img-bg-in {
        @include sm {
          height: auto;
          // padding-top: 50%
          padding-top: 40%;
          // .icon
 }          //   top: 10px
        @include xs {
          padding-top: 40%; }
        .icon {
          top: 10px; } }
      &__btn {
        @include sm {
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          width: 100%; } }
      &__content {
        padding: 20px;
        padding-left: 0;
        padding-right: 25px;
        position: relative;
        @include sm {
          padding: 20px; }
        @include xs {
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 10px; } }
      &__content-top {
        display: inline-flex;
        @include sm {
          max-width: 250px;
          margin-top: -50px;
          // margin-top: 0
 }          // margin-bottom: 0
        .item-label {
          margin-right: 20px; } }
      &__title {
        padding-bottom: 0;
        padding-right: 150px;
        .h4, h4 {
          order: 0; }
        .item__content-top {
          order: 1; }
        @include lg {
          padding-right: 0;
          .h4, h4 {
            padding-right: 150px; } }
        @include sm {
          padding-right: 150px;
          margin-bottom: 20px;
          .h4, h4 {
            padding-right: 0;
            order: 1; }
          .item__content-top {
            order: 0; } }
        @include xs {
          padding-right: 0;
          margin-bottom: 5px; } }

      &__price {
        position: absolute;
        top: 15px;
        right: 0;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 1, 0.11);
        text-align: center;
        padding: 2px 10px;
        @include xs {
          position: static;
          text-align: left;
          box-shadow: none;
          padding: 0;
          padding-top: 5px; } }
      &__text {
        display: block;
        @include xs {
          font-size: 14px;
          margin-bottom: 10px; } }

      &__info {
        border-bottom-width: 0;
        .item-info-item {
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(8) {
            display: inline-flex; } }
        @include xs {
          border-bottom-width: 1px; } } } } }




.item-label {
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  padding: 4px 5px;
  display: flex;
  justify-content: center;
  min-width: 104px;
  align-items: center;
  &.is-available {
    background-color: $c-accent; }
  &.is-intransit {
    background-color: #0073d5; }
  &.is-reserve {
    background-color: #ecac01; }
  &.is-sold {
    background-color: #dc002a; }
  &.is-ordered {
    background-color: #0073d5; }
  &__icon {
    font-size: 0;
    margin-right: 5px;
    .icon {
      font-size: 11px;
      fill: currentColor; }
    .icon-truck {
      font-size: 15px; } } }

.item-info {
  font-size: 0; }

.item-info-item {
  font-size: 13px;
  color: #8d8d8d;
  display: inline-flex;
  width: 50%;
  margin-bottom: 7px;
  align-items: center;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
  &__icon {
    font-size: 0;
    margin-right: 7px;
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    text-align: center;
    .icon {
      fill: currentColor;
      font-size: 16px; }
    .icon-hatchback {
      position: relative;
      top: 4px;
      font-size: 22px; } } }


