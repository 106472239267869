.breadcrumbs {
  font-size: 15px;
  &__item {
    display: inline-block;
    color: #fff; }
  &__link {
    color: #fff;
    font-size: 15px;
    @include light;
    &.is-active {
      color: #9b9b9b;
      pointer-events: none; } } }
