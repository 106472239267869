.contact-info {
  &__img {
    @include cover;
    height: 100%;
    // padding-top: 60%
    // margin-bottom: 20px
    @include sm {
      padding-top: 60%;
      margin-bottom: 20px;
      height: auto; } }
  &__title {
    margin-bottom: 15px; } }

.contact-meta {
  font-size: 15px;
  &__tr {
    &:not(:last-child) {
      border-bottom: 1px solid  $c-border; } }
  &__th {
    font-weight: 400;
    text-align: left;
    @include bold;
    padding-top: 8px;
    padding-bottom: 8px; }
  &__td {
    @include light;
    color: #77787b;
    padding-top: 8px;
    padding-bottom: 8px;
    a {
      text-decoration: underline;
      color: $c-accent; }
    span {
      display: block;
      &:not(:last-child) {
        margin-bottom: 10px; } } } }
