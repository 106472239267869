.links-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 4px; } }
  &__link {
    text-transform: uppercase;
    font-size: 15px;
    @include bold;
    transition: color .3s;
    @include hover {
      color: $c-accent; } } }
