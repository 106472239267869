.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 15;
  @include lg {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    &::before {
      content: '';
      z-index: 1;
      background-color: #191919;
      width: 100%;
      height: 45px;
      top: 0;
      left: 0;
      position: absolute; } }
  &.is-active {
    .header__inner {
      transform: translate(0, 0); } }
  &__inner {
    position: relative;
    transition: transform .3s;
    @include lg {
      position: absolute;
      width: 100%;
      height: calc(100vh - 45px);
      top: 100%;
      left: 0;
      background-color: #fff;
      transform: translate(0, -100%);
      overflow: auto;
      background-color: #e6e6e6; } }
  &__right-mob {
    position: relative;
    padding-right: 7px;
    z-index: 1;
    display: none;
    .contact {
      margin-bottom: 0; }
    @include lg {
      display: flex;
      align-items: center; } }

  &__logo {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0; }
  &__top {
    background-color: #191919;
    // overflow: hidden
    position: relative;
    @include lg {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 160px;
      padding-right: 0; }
    @include md {
      padding-left: 110px; }
    @include xs {
      padding-left: 15px;
      padding-top: 40px; } }
  &__contacts {
    height: 45px;
    @include lg {
      height: auto;
      // .contact
      //   &:first-child
 } }      //     display: none
  &__bottom {
    background-color: #fff;
    @include xl {
      padding-left: 75px;
      padding-right: 75px; }
    @include lg {
      padding-left: 160px;
      padding-right: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #e6e6e6; }
    @include md {
      padding-left: 110px; }
    @include xs {
      padding-left: 15px; } }
  &__nav {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include lg {
      height: auto;
      display: block; } }
  &__right {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 7px;
    @include lg {
      display: none; } }
  &__social-mob {
    display: none;
    @include lg {
      display: block; } }

  &__social {
    margin-left: 50px;
    @include xl {
      position: absolute;
      bottom: 0;
      right: 7px;
      transform: translate(0, 100%);
      margin-left: 0; } } }

.language-chooser {
  padding-left: 15px;
  li {
    opacity: .5;
    &.active {
      opacity: 1; } }
  @include lg {
    padding-left: 0;
    padding-top: 15px;
    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 10px; } }

    img {
      width: 30px;
      height: 20px; } } }

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  @include lg {
    display: block;
 }    // text-align: center
  .btn {
    &__title {
      padding: 13px 10px; } } }


.contact {
  $this: &;
  color: #b6b6b6;
  font-size: 14px;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  align-items: center;
  a {
    transition: color .3s;
    &.is-green {
      color: $c-accent; } }
  @include hover {
    a {
      color: $c-accent;
      .icon {
        fill: $c-accent; }
      &.is-green {
        text-decoration: underline; } } }
  @include lg {
    // justify-content: center
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0; }
  @include xs {
    font-size: 14px; }

  &__icon {
    font-size: 0;
    margin-right: 10px;
    .icon {
      fill: #b6b6b6;
      font-size: 14px;
      @include lg {
        font-size: 18px; }
      @include xs {
        font-size: 14px; } }
    .icon-envelope {
      font-size: 18px; } }

  &--content {
    font-size: 15px;
    margin-left: 0;
    margin-right: 0;
    @include lg {
      font-size: 15px; }
    @include xs {
      font-size: 15px; }
    #{$this}__icon {
      .icon {
        font-size: 15px;
        @include lg {
          font-size: 15px; }
        @include xs {
          font-size: 15px; } }
      .icon-telephone {
        position: relative;
        top: -1px; }
      .icon-envelope {
        font-size: 18px;
        @include lg {
          font-size: 18px; }
        @include xs {
          font-size: 18px; } } } } }

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $c-accent;
  width: 152px;
  height: 114px;
  @include md {
    width: 100px;
    height: 75px; }
  @include xs {
    width: 75px;
    height: 56px; }
  .icon {
    fill: #fff;
    stroke: #fff;
    font-size: 205px; } }

.toggle {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: 9px;
  margin-left: 15px;

  span:after, span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }

  span:after {
    top: 18px; }

  span {
    position: relative;
    display: block; }

  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }


  &.is-active span {
    background-color: transparent; }

  &.is-active span:before {
    transform: rotate(45deg) translate(-1px, 0px); }

  &.is-active span:after {
    transform: rotate(-45deg) translate(6px, -7px); } }
