.icon-360-degrees {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-battery {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-calendar {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-call {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-car-insurance {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-car-man {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-car {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-check-sircle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-check {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-clock {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-eco {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-electric-car {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-employee {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-engine {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-envelope {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-eye {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}
.icon-file {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-files {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-frame-landscape {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-hatchback {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-idea {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-kyivstar {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-lightbulb {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-list {
  width: 0.75em;
  height: 1em;
  fill: initial;
}
.icon-location {
  width: 0.63em;
  height: 1em;
  fill: initial;
}
.icon-logo {
  width: 1em;
  height: 1em;
  fill: #FFFFFF;
}
.icon-loupe {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-map {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-medal {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-money {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-payment-terminal {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-play-button {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-plug {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-plus-circle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-plus-filled {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-quote {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-race-track {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-reload {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-repair-service {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-research {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-road {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 950.32em;
  height: 1em;
  fill: initial;
}
.icon-settings {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-shield {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-shifter {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-sold {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-speedometer {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-spring {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-squared-menu {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-support {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-swipe {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-telephone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-truck {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-turbo {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-video-camera {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-vodafone {
  width: 0.99em;
  height: 1em;
  fill: initial;
}
.icon-work-tools {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-writing {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-youtube {
  width: 1em;
  height: 1em;
  fill: initial;
}
