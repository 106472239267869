.footer {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #fff;
  @include md {
    padding-top: 30px;
    padding-bottom: 30px; }
  &__title {
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 25px;
    &::before {
      content: '';
      position: absolute;
      width: 53px;
      height: 1px;
      background-color: #bcbcbc;
      bottom: 0;
      left: 0; } } }

.footer-contacts {
  &__phone {
    color: #191919;
    font-size: 15px;
    @include bold;
    margin-bottom: 22px;
    @include md {
      margin-bottom: 12px; } }
  &__text {
    font-size: 15px;
    color: #727171;
    margin-bottom: 22px;
    @include md {
      margin-bottom: 12px; }
    @include light; }
  &__link {
    font-size: 15px;
    text-decoration: underline;
    color: $c-accent;
    margin-bottom: 22px;
    display: inline-block;
    @include light;
    @include md {
      margin-bottom: 12px; } }
  &__copy {
    text-transform: uppercase;
    @include light;
    font-size: 12px; }
  &__social {
    margin-bottom: 22px;
    @include md {
      margin-bottom: 12px; } } }
