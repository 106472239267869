.table {
  $this: &;
  @include light;
  font-size: 15px;
  @include sm {
 }    // font-size: 13px
  @include sm {
    width: 1100px;
    table-layout: fixed; }
  &__th {
    font-weight: 400;
    background-color: #f2f2f2;
    border: 1px solid  $c-border;
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    &:nth-child(1) {
      padding: 0;
      width: 59.4%;
      @include xs {
 } }        // width: 10%
    &:nth-child(2) {
      width: 20.3%;
      @include xs {
 } }        // width: 50%
    &:nth-child(3) {
      width: 20.3%;
      @include xs {
 } } }        // width: auto
  &__th-in {
    font-weight: 400;
    position: relative;
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    &:not(:last-child) {
      border-right: 1px solid  $c-border; } }
  &__tr-in {
    &:not(:last-child) {
      border-bottom: 1px solid  $c-border; }
    &.is-gray {
      background-color: #fafafa;
      &:not(:last-child) {
        border-bottom: 2px solid  #fff; } } }
  &__td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid  $c-border; }
  &__td-in {
    vertical-align: middle;
    &:not(:last-child) {
      border-right: 1px solid  $c-border; } }

  &__table-in {
    border: 0;
    #{$this}__td-in {
      &:nth-child(1) {
        width: 22%;
        @include md {
          width: 32%; } }
      &:nth-child(2) {
        width: 44%;
        @include md {
          width: 34%; } }
      &:nth-child(3) {
        width: 44%;
        @include md {
          width: 34%; } } } }
  &__table-gray {
    #{$this}__td-in {
      padding-top: 8px;
      padding-bottom: 8px; } }

  &--credit {
    &__th-fst {
      width: 22%;
      @include md {
        width: 32%; } }
    &__th-scnd {
      width: 44%;
      @include md {
        width: 34%; } }
    &__th-thrd {
      width: 44%;
      @include md {
        width: 34%; } }
    &__th-forth {}
    &__th-fifth {} } }

.content-table {
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 1, 1, 0.19);
  &__title {
    background-color: #f6f6f6;
    padding: 12px 15px;
    border-bottom: 1px solid  $c-border; } }

.prices-table {
  $this: &;
  text-align: left;

  &__title {
    display: flex;
    align-items: center;
    padding-left: 25px;
    @include lg {
      padding-left: 15px; } }
  &__th {
    @include bold;
    text-transform: uppercase;
    padding-right: 15px;
    &--title {
      width: percentage(430/830);
      @include lg {
        width: 80%; }
      @include xs {
        width: 70%; } }
    &--price {
      width: percentage(140/830);
      @include lg {
        width: 20%; }
      @include xs {
        width: 30%; } }
    &--btn {
      width: percentage((830 - 430 - 140)/830);
      padding-right: 0;
      @include lg {
        display: none; } } }
  &__tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 15px;
    padding-left: 25px;
    border-bottom: 1px solid  $c-border;
    @include lg {
      padding-left: 15px; } }
  &__td {
    padding-right: 15px;
    &:nth-child(1) {
      @extend #{$this}__th--title;
      padding-right: 10%;
      @include lg {
        padding-right: 15px; } }
    &:nth-child(2) {
      @extend #{$this}__th--price; }
    &:nth-child(3) {
      @extend #{$this}__th--btn;
      @include lg {
        display: block;
        width: 100%;
        padding-top: 15px; } } } }
