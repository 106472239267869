.range-slider {
  .noUi-handle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: $c-accent;
    border: 0;
    box-shadow: none;
    top: -5px;
    cursor: pointer;
    &::before, &::after {
      display: none; } }
  .noUi-handle-lower {
    right: -8px !important; }
  .noUi-handle-upper {
    right: -8px !important; }
  .noUi-horizontal {
    height: 3px;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    background-color: $c-text;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -5px;
      display: inline-block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background-color: #636363;
      pointer-events: none; }
    &::before {
      left: -4px; }
    &::after {
      right: -8px; } }
  .noUi-connects {
    border-radius: 0; }
  .noUi-connect {
    border-radius: 0;
    background-color: $c-accent;
    cursor: pointer; } }

.range-slider--one-value {
  .noUi-horizontal {
    @include before {
      display: none; }
    @include after {
      display: none; } }
  .noUi-connects {
 }    // height: 10px
  .noUi-base {
 }    // height: 10px
  .noUi-horizontal {
    height: 10px;
    box-shadow: inset 0px 1px 5px 0px rgba(0, 0, 1, 0.18);
    background-color: rgb(242, 242, 242); }
  .noUi-handle {
    height: 30px;
    width: 30px;
    right: -22px !important;
    top: -10px;
    background-color: #fff;
    box-shadow: 0px 6px 7.44px 0.56px rgba(0, 0, 1, 0.13);
    &::before {
      content: '';
      @include vcenter;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      display: inline-block;
      background-color: $c-accent; } } }

.range-slider {
  $this: &;
  &__title {
    @include light;
    font-size: 15px;
    margin-bottom: 5px; }

  &--one-value {
    #{$this}__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include medium;
      color: #878787;
      input {
        width: 170px;
        height: 50px;
        border: 1px solid  $c-border;
        font-size: 26px;
        @include bold;
        text-align: center;
        padding: 5px;
        &:focus {
          border-color: $c-accent; } } }
    #{$this}__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      @include light;
      color: #a1a1a1;
      padding-top: 5px; } } }
