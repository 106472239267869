strong {
  @include bold; }

.h4, h4 {
  @include bold;
  font-size: 18px;
  text-transform: uppercase;
  &--white {
    color: #fff; }
  &--medium {
    @include medium; }
  &--light {
    @include light; }
  &--lg {
    font-size: 21px; }
  &--ttn {
    text-transform: none; } }

.h2, h2 {
  font-size: 26px;
  @include bold;
  line-height: 1;
  a {
    transition: color .3s;
    @include hover {
      text-decoration: underline; } }
  &--light {
    @include light; }
  &--ttu {
    text-transform: uppercase; }
  &--ttn {
    text-transform: none; }
  &--white {
    color: #fff; }
  &--green {
    color: $c-accent; }
  &--sm {
    font-size: 24px; } }

.h3, h3 {
  @include bold;
  font-size: 15px;
  text-transform: uppercase;
  a {
    transition: color .3s;
    @include hover {
      text-decoration: underline; } }
  &--green {
    color: $c-accent; }
  &--white {
    color: #fff; }
  &--ttn {
    text-transform: none; } }


.title {
  font-size: 32px;
  color: $c-text;
  @include bold;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.15;
  position: relative;
  @include css-lock(28, 32, 575, 1199, font-size);
  small {
    font-size: 15px; }
  .link {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: calc(100% + 30px);
    white-space: nowrap;
    text-transform: none;
    font-size: 15px;
    @include light;
    @include md {
      position: static;
      margin-top: 15px;
      justify-content: center; } }
  &.has-mb {
    margin-bottom: 15px; }

  &--white {
    color: #fff; }
  &--black {
    color: #202020; }
  &--ttn {
    text-transform: none; } }

.title-underlined {
  position: relative;
  padding-bottom: 14px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 53px;
    height: 1px;
    background-color: $c-text; }
  &--white {
    &::before {
      background-color: #fff; } }
  &--sm {
     padding-bottom: 10px; }
  &--green {
    &::before {
      background-color: $c-accent; } }
  &--center {
    &::before {
      left: 50%;
      transform: translate(-50%, 0); } }
  &--gray {
    &::before {
      background-color: #a8a8a8; } } }



.text {
  font-size: 15px;
  text-align: justify;
  @include light;
  &--black {
    color: #202020; }
  &--white {
    color: #fff; }
  &--gray {
    color: #828282; }
  &--medium {
    @include medium; }
  &--thin {
    @include thin; }
  &--sm {
    font-size: 14px; }
  &--gray-white {
    color: #a8a8a8; }
  &--p-mb {
    margin-bottom: 45px;
    @include md {
      margin-bottom: 25px; }
    p {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } } } }
